import Layout from "../components/layout/Layout"
import "./Projects.scss"
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState, useContext } from "react"
import Drawer from 'react-modern-drawer';
import { ProjectDetail } from "../components/project/ProjectDetail"
import { format } from "date-fns";
import { TableCell } from "../components/project/ProjectTable"
import { ColumnDef, SortingState } from "@tanstack/react-table"
import { ProjectFilter } from "../components/project/ProjectFilter"
import { ConfirmDialog } from "../components/layout/Dialog"
import { ProjectFolderService, ProjectFolderViewDTO, ProjectService, ProjectViewDTO } from "../openapi/requests"
import { useInView } from 'react-intersection-observer'
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query"
import { Filter } from "../types/types";
import { GenerateODataFilter } from "../helpers/odataFunctions";
import { useProjectFolderServiceGetProjectFolderKey, useProjectServiceGetProjectKey } from "../openapi/queries";
import { ReactTable } from "../components/layout/elements/ReactTable";
import { ConfigContext } from "../contexts/ConfigContext";
import { AuthContext } from "../contexts/AuthContext";
import { ReactComponent as Cutout } from '../assets/icons/cutout.svg';
import { ReactComponent as InSitu } from '../assets/icons/in-situ.svg';
import { ReactComponent as Folder } from '../assets/icons/folder.svg';
import { ReactComponent as Pencil } from '../assets/icons/pencil.svg';
import { ReactComponent as Upload } from '../assets/icons/upload.svg';
import { ReactComponent as Share } from '../assets/icons/share.svg';
import { useNavigate } from "react-router-dom";
import { ProjectStatus, ProjectStatusLabels } from "../models/enums";
import EditProject from "../components/project/EditProject";
import UploadToDam from "../components/project/UploadToDam";
import { ProjectShare } from "../components/project/ProjectShare";
import { UIContext } from "../contexts/UIContext";


export const isProject = (projectOrFolder: ProjectViewDTO | ProjectFolderViewDTO): boolean => {
    if((projectOrFolder as ProjectFolderViewDTO).numberOfProjects !== undefined){
        return false;
    }
    return true;
}

export const getProjectType = (projectOrFolder: ProjectViewDTO | ProjectFolderViewDTO): "cutout" | "insitu" | "folder" => {
    if(isProject(projectOrFolder)){
        const project = projectOrFolder as ProjectViewDTO;
        if(project.projectType === "cutout"){
            return "cutout"
        }
        return "insitu"
    }
    return "folder";
}

type ProjectOrFolder = ProjectViewDTO | (ProjectFolderViewDTO & {workflowLink: undefined});

export const Projects: React.FC = () => {

    const { ref, inView } = useInView();
    const navigate = useNavigate();

    const queryClient = useQueryClient();
    const { odataFilters, featureFlags } = useContext(ConfigContext);
    const { hasPermission } = useContext(AuthContext);
    const { selectedProjectView, setSelectedProjectView } = useContext(UIContext);

    const [showOwned, setShowOwned] = useState(true)
    const [showShared, setShowShared] = useState(false)
    const [showProject, setShowProject] = useState(false)
    const [showProjectEditor, setShowProjectEditor] = useState(false)
    const [showProjectUpload, setShowProjectUpload] = useState(false)
    const [showProjectShare, setShowProjectShare] = useState(false)
    const [rowCount, setRowCount] = useState(0)
    const [selectedProject, setSelectedProject] = useState<ProjectViewDTO | ProjectFolderViewDTO | null>(null)
   // const [selectedProjectFolder, setSelectedProjectFolder] = useState<ProjectFolderViewDTO | null>(null)

    const [sorting, setSorting] = useState<SortingState>([{id: 'timestampCreate', desc: true}]);
    const [filters, setFilters] = useState<Filter[]>([]);
 
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showCopyConfirm, setShowCopyConfirm] = useState(false);

    const headerRef = useRef<HTMLDivElement>({} as HTMLDivElement)

    const {
        data: folders,
        fetchNextPage : folder_fetchNextPage,
        hasNextPage: folder_hasNextPage,
        isLoading: folder_isLoading,
        isFetching: folder_isFetching,    
    } = useInfiniteQuery({        
        queryKey: [useProjectFolderServiceGetProjectFolderKey, sorting, filters, odataFilters, showOwned, showShared, selectedProjectView],    
        initialPageParam: "0",   
        refetchInterval: 60000,   
        enabled: featureFlags.ProjectFolders,              
        queryFn: async ({ pageParam = "0" }) => {

            let combinedFilters = [...filters, ...odataFilters];

            //IsOwned   
            if (showOwned) {
                combinedFilters.push({ name: "Owner", property: "IsOwner", type: "", values: [{value: "true"}] });
            }

            //IsShared
            if (showShared) {
                combinedFilters.push({ name: "Owner", property: "IsOwner", type: "", values: [{value: "false"}] });
                combinedFilters.push({ name: "Shared", property: "IsShared", type: "", values: [{value: "true"}] });
            }

            return await ProjectFolderService.getProjectFolder(undefined, pageParam, GenerateODataFilter(combinedFilters), undefined, (sorting.length > 0) ? sorting.map((s) => `${s.id} ${s.desc ? 'desc' : 'asc'}`).join(',') : undefined, undefined, 'true')
        },
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage["@odata.nextLink"]) {
                let url = new URL(lastPage["@odata.nextLink"]);
                return url.searchParams.get("$skip")
            }
            return null;
        }
    });

    const {
        data: projects,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isFetching,    
    } = useInfiniteQuery({        
        queryKey: [useProjectServiceGetProjectKey, sorting, filters, odataFilters, showOwned, showShared],    
        initialPageParam: "0",   
        refetchInterval: 60000,                 
        queryFn: async ({ pageParam = "0" }) => {

            let combinedFilters = [...filters, ...odataFilters];
            combinedFilters.push({ name: "folder", property: "folderId", type: "", values: [{value: "null"}] });

            //IsOwned   
            if (showOwned) {
                combinedFilters.push({ name: "Owner", property: "IsOwner", type: "", values: [{value: "true"}] });
            }

            //IsShared
            if (showShared) {
                combinedFilters.push({ name: "Owner", property: "IsOwner", type: "", values: [{value: "false"}] });
                combinedFilters.push({ name: "Shared", property: "IsShared", type: "", values: [{value: "true"}] });
            }

            return await ProjectService.getProject(undefined, pageParam, GenerateODataFilter(combinedFilters), undefined, (sorting.length > 0) ? sorting.map((s) => `${s.id} ${s.desc ? 'desc' : 'asc'}`).join(',') : undefined, undefined, 'true')
        },
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage["@odata.nextLink"]) {
                let url = new URL(lastPage["@odata.nextLink"]);
                return url.searchParams.get("$skip")
            }
            return null;
        }
    });

    const filteredProjects = useMemo(() => {
        return [...folders?.pages.map(x => x.value).flat() ?? [], ...projects?.pages.map(x => x.value).flat() ?? []] as ProjectOrFolder[];
    }, [projects, folders])
    
    const onScroll = (ref: MutableRefObject<HTMLDivElement>) => {
        const elemPosition = headerRef.current.getBoundingClientRect();
        if (ref.current.scrollTop >= elemPosition.top) {
            headerRef.current.classList.add("fixed")
        } else {
            headerRef.current.classList.remove("fixed")
        }
    }

    const onSelect = useCallback((project: ProjectViewDTO | ProjectFolderViewDTO) => {
        if(isProject(project)){
            setSelectedProject(project as ProjectViewDTO);
            setShowProject(true);
        }else{
            navigate("/create/projectfolders/" + project.id);
        }
    }, [navigate]);

    const onClose = () => {
        setShowProject(false)
        setShowProjectShare(false)
    }

    const onProjectCopy = useCallback(async (projectOrFolder: ProjectViewDTO | ProjectFolderViewDTO | null) => {
        if (projectOrFolder && isProject(projectOrFolder)) {
            await ProjectService.postProjectCopy(projectOrFolder.id)
            queryClient.invalidateQueries({ queryKey: [useProjectServiceGetProjectKey]})
            setShowCopyConfirm(false);
        }
    }, [queryClient]);

    const onProjectDelete = useCallback(async (projectOrFolder: ProjectViewDTO | ProjectFolderViewDTO | null) => {
        if (projectOrFolder && isProject(projectOrFolder)) {
            await ProjectService.deleteProject(projectOrFolder.id);
            queryClient.invalidateQueries({ queryKey: [useProjectServiceGetProjectKey]})
            setShowDeleteConfirm(false);
        }else if(projectOrFolder){
            await ProjectFolderService.deleteProjectFolder(projectOrFolder.id);
            queryClient.invalidateQueries({ queryKey: [useProjectFolderServiceGetProjectFolderKey]})
            setShowDeleteConfirm(false);
        }
    }, [queryClient]);

    const onProjectShared = useCallback(async (projectOrFolder: ProjectViewDTO | ProjectFolderViewDTO | null) => {
        if (projectOrFolder && isProject(projectOrFolder)) {
            queryClient.invalidateQueries({ queryKey: [useProjectServiceGetProjectKey] })
            queryClient.refetchQueries({ queryKey: [useProjectServiceGetProjectKey] })
            setShowProjectShare(false);
        } else if (projectOrFolder) {        
            queryClient.invalidateQueries({ queryKey: [useProjectFolderServiceGetProjectFolderKey] })
            queryClient.refetchQueries({ queryKey: [useProjectFolderServiceGetProjectFolderKey] })
            setShowProjectShare(false);
        }        
    }, [queryClient])

    useEffect(() => {
        setRowCount((projects?.pages[0]["@odata.count"] ?? 0) + (folders?.pages[0]["@odata.count"] ?? 0));
    }, [projects, folders]);

    useEffect(() => {
        if (inView) {
            if(folder_hasNextPage){
                !folder_isFetching && folder_fetchNextPage()
            }else{
                !isFetching && fetchNextPage()
            }
        }
    }, [inView, fetchNextPage, isFetching, folder_hasNextPage, folder_isFetching, folder_fetchNextPage])

    useEffect(() => {
        queryClient.resetQueries({queryKey: [useProjectServiceGetProjectKey], stale: false})
        queryClient.resetQueries({queryKey: [useProjectFolderServiceGetProjectFolderKey], stale: false})
    },[sorting, filters, showOwned, queryClient]);

    useEffect(() => {
        setShowOwned(selectedProjectView === "owned") 
        setShowShared(selectedProjectView === "shared")  
    }, [selectedProjectView]);

    const columns = useMemo<ColumnDef<ProjectOrFolder, any>[]>(
        () => [
            {
                id: 'icon',
                header: '',
                accessorKey: 'icon',
                cell: (props) => <div className="icon">
                    {(getProjectType(props.row.original) === "insitu") && <InSitu /> }
                    {(getProjectType(props.row.original) === "cutout") && <Cutout /> }
                    {(getProjectType(props.row.original) === "folder") && <Folder /> }
                </div>
            },
            {
                id: 'name',
                header: 'Name',
                accessorKey: 'name',
                cell: (props) => <TableCell className="title" row={props.row} onSelect={() => onSelect(props.row.original)}>{props.getValue()}</TableCell>
            },
            {
                id: 'modifiedUserName',
                header: 'User',
                accessorKey: 'modifiedUserName',
                cell: (props) => <TableCell row={props.row} onSelect={() => onSelect(props.row.original)}><strong>{props.row.original.modifiedUserName}</strong></TableCell>
            },
            {
                id: 'timestampCreate',
                header: 'Date',
                accessorKey: 'timestampCreate',
                cell: (props) => <TableCell row={props.row} onSelect={() => onSelect(props.row.original)}>{format(new Date(props.row.original.timestampModify ?? "1900-01-01"), "dd.MM.yyyy")}</TableCell>
            },
            {
                id: 'status',
                header: 'Status',
                accessorKey: 'status',
                cell: (props) => <TableCell row={props.row} onSelect={() => onSelect(props.row.original)}>{ProjectStatusLabels[(props.getValue() as ProjectStatus)]}</TableCell>
            },
            {
                id: 'actions',
                cell: (props) => <div className="table-actions">
                    
                    <button onClick={() => { setSelectedProject(props.row.original); setShowProjectShare(true); }}>
                        <Share />
                    </button>
                   
                    {(getProjectType(props.row.original) === "insitu") ?
                        <button onClick={() => { setSelectedProject(props.row.original); setShowCopyConfirm(true) }}>
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="4" y="1" width="12" height="12" rx="1" stroke="black" />
                                <rect x="1" y="4" width="12" height="12" rx="1" fill="white" stroke="black" />
                                <path d="M7 7L7 13" stroke="black" />
                                <path d="M10 10L4 10" stroke="black" />
                            </svg>
                        </button> : null
                    }
                    <button onClick={() => { setSelectedProject(props.row.original); setShowDeleteConfirm(true) }}>
                        <svg width="17" height="17" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 4L0 4" stroke="black" />
                            <path d="M5 4V2C5 1.44772 5.44772 1 6 1H10C10.5523 1 11 1.44772 11 2V4" stroke="black" />
                            <path d="M5 14L5 7" stroke="black" />
                            <path d="M8 14L8 7" stroke="black" />
                            <path d="M11 14L11 7" stroke="black" />
                            <path d="M2 4V15C2 16.1046 2.89543 17 4 17H12C13.1046 17 14 16.1046 14 15V4" stroke="black" />
                        </svg>
                    </button>
                    <button onClick={() => { setSelectedProject(props.row.original); setShowProjectEditor(true) }}>
                        <Pencil />
                    </button>
                    {hasPermission("ApproveOrders") && <button onClick={() => { setSelectedProject(props.row.original); setShowProjectUpload(true) }}>
                        <Upload />
                    </button> }
                </div>
            }
        ],
        [hasPermission, onSelect]
    )

    return (
        <>
            <Layout className="projects" onScroll={(ref) => onScroll(ref)}>

                <header>
                   <div>
                        {hasPermission('ViewAllOrders') && <button className={(selectedProjectView === "all") ? "selected" : ""} onClick={() => { setSelectedProjectView("all")}}>All</button>}
                        <button className={(selectedProjectView === "shared") ? "selected" : ""} onClick={() => { setSelectedProjectView("shared")}}>Shared</button>
                        <button className={(selectedProjectView === "owned") ? "selected" : ""} onClick={() => { setSelectedProjectView("owned")}}>My Projects</button>
                    </div>
                </header>

                <div className="projects-header">
                    <h1>Project Overview<span>({rowCount})</span></h1>
                </div>

                <div className='projects-filters' ref={headerRef}>
                    <ProjectFilter filters={filters} onChange={e => setFilters(e)} isOpen={true} items={filteredProjects} properties={[
                        { name: 'Search', property: 'name', type: 'search' },
                        { name: 'Status', property: 'status', type: 'select', itemsOverride: [0, 10, 20, 40, 50, 99].map(e => ({value: e, label: ProjectStatusLabels[e as ProjectStatus]})) },
                        { name: 'Date', property: 'timestampCreate', type: 'daterange' }
                    ]} />
                </div>

                <ConfirmDialog onConfirm={() => onProjectCopy(selectedProject)} open={showCopyConfirm} onCancel={() => setShowCopyConfirm(false)} text='By making this selection, you are about copy the selected project.' title='Are you sure?' />
                <ConfirmDialog onConfirm={() => onProjectDelete(selectedProject)} open={showDeleteConfirm} onCancel={() => setShowDeleteConfirm(false)} text='By making this selection, you are about delete the selected project.' title='Are you sure?' />

                <ReactTable
                    data={filteredProjects}
                    columns={columns}
                    isLoading={isLoading || folder_isLoading}
                    isFetching={hasNextPage || isFetching || folder_hasNextPage || folder_isFetching}
                    sorting={sorting}
                    setSorting={setSorting}
                />

                {/* {isLoading ? (
                    <p>Loading...</p>
                ) : isError ? (
                    <p>Error</p>
                ) : (
                    <ProjectTable columns={columns} data={filteredProjects} globalFilter={""} filters={[]} onStateChange={onTableStateChange} />
                )} */}

                {(hasNextPage || folder_hasNextPage) ? <div ref={ref}>Fetching...</div> : null}

            </Layout>

            <EditProject isOpen={showProjectEditor} onClose={() => setShowProjectEditor(false)} projectOrFolder={selectedProject} />
            <UploadToDam isOpen={showProjectUpload} onClose={() => setShowProjectUpload(false)} projectOrFolder={selectedProject} />

            <Drawer className="project-detail-overlay" enableOverlay={false} open={showProject} size={"100%"} direction="bottom" onClose={onClose}>
                <div className="project-detail-overlay-header">
                    <button onClick={onClose}>
                        <svg width="56" height="28" viewBox="0 0 56 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="55" height="27" rx="13.5" fill="#1A1A1A" stroke="white" />
                            <path d="M30.5 10.5L23.5 17.5" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M23.5 10.5L30.5 17.5" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>
                <div className="project-detail-overlay-content">
                    { showProject ? <ProjectDetail projectId={selectedProject?.id} /> : null }
                </div>
            </Drawer>

            <ProjectShare isOpen={showProjectShare} projectOrFolder={selectedProject} onShared={() => onProjectShared(selectedProject)} onClose={() => setShowProjectShare(false) } />
           
        </>
    )
}

export default Projects;
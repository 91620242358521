/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderlineEditDTO } from '../models/OrderlineEditDTO';
import type { OrderlineViewDTO } from '../models/OrderlineViewDTO';
import type { PullCoDImageAsAssetResponse } from '../models/PullCoDImageAsAssetResponse';
import type { PullCoDImageResponse } from '../models/PullCoDImageResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrderlineService {

    /**
     * @param key 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns OrderlineViewDTO Success
     * @throws ApiError
     */
    public static getOrderOrderline(
key: number,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<Array<OrderlineViewDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/{key}/Orderline',
            path: {
                'key': key,
            },
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns OrderlineViewDTO Success
     * @throws ApiError
     */
    public static postOrderOrderline(
key: number,
requestBody?: OrderlineEditDTO,
): CancelablePromise<OrderlineViewDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/{key}/Orderline',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param id 
     * @returns OrderlineViewDTO Success
     * @throws ApiError
     */
    public static getOrderOrderline1(
key: number,
id: number,
): CancelablePromise<OrderlineViewDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/{key}/Orderline/{id}',
            path: {
                'key': key,
                'id': id,
            },
        });
    }

    /**
     * @param key 
     * @param id 
     * @param requestBody 
     * @returns OrderlineViewDTO Success
     * @throws ApiError
     */
    public static putOrderOrderline(
key: number,
id: number,
requestBody?: OrderlineEditDTO,
): CancelablePromise<OrderlineViewDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/order/{key}/Orderline/{id}',
            path: {
                'key': key,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteOrderOrderline(
key: number,
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/order/{key}/Orderline/{id}',
            path: {
                'key': key,
                'id': id,
            },
        });
    }

    /**
     * @param key 
     * @param id 
     * @returns PullCoDImageResponse Success
     * @throws ApiError
     */
    public static postOrderOrderlineRequestManualAdjusments(
key: number,
id: number,
): CancelablePromise<PullCoDImageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/{key}/Orderline/{id}/requestManualAdjusments',
            path: {
                'key': key,
                'id': id,
            },
        });
    }

    /**
     * @param key 
     * @param id 
     * @returns PullCoDImageAsAssetResponse Success
     * @throws ApiError
     */
    public static postOrderOrderlineAddToSolutionizer(
key: number,
id: number,
): CancelablePromise<PullCoDImageAsAssetResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/{key}/Orderline/{id}/AddToSolutionizer',
            path: {
                'key': key,
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param key 
     * @param size 
     * @param filetype 
     * @returns any Success
     * @throws ApiError
     */
    public static getOrderlineThumbnail(
id: number,
key?: number,
size: number = 512,
filetype: string = 'webp',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orderline/{id}/thumbnail',
            path: {
                'id': id,
            },
            query: {
                'key': key,
                'size': size,
                'filetype': filetype,
            },
        });
    }

    /**
     * @param id 
     * @param key 
     * @param size 
     * @param filetype 
     * @returns any Success
     * @throws ApiError
     */
    public static getOrderlinesThumbnail(
id: number,
key?: number,
size: number = 512,
filetype: string = 'webp',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orderlines/{id}/thumbnail',
            path: {
                'id': id,
            },
            query: {
                'key': key,
                'size': size,
                'filetype': filetype,
            },
        });
    }

}

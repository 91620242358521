import { Button, Input, message, Popconfirm, Space, Table, Tag, Image, Flex, Select, Descriptions } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { CheckCircleOutlined, CheckSquareOutlined, CloseOutlined, PlusOutlined, SnippetsOutlined, StopOutlined } from '@ant-design/icons';
import CreateMaterial from './CreateMaterial';
import EditMaterial from './EditMaterial';
import ApproveMaterial from './ApproveMaterial';
import { useInternalClientServiceGetInternalClient, useMaterialServiceDeleteMaterial, useMaterialServiceGetMaterial, useMaterialServicePutMaterial } from '../../../openapi/queries';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { GenerateODataFilter } from '../../../helpers/odataFunctions';
import { Filter } from '../../../types/types';
import { MaterialViewDTO } from '../../../openapi/requests';
import { useDebounce } from 'use-debounce';
import { BASE_PATH } from '../../..';
import { AssetApprovalStatus, AssetApprovalStatusLabels, AssetApprovalStatusOptions } from '../../../models/enums';
import { AuthContext } from '../../../contexts/AuthContext';
import { UserSelector } from '../Inputs/UserSelector';
import { keepPreviousData } from '@tanstack/react-query';
import { MetadataSelector } from '../Inputs/MetadataSelector';

const metadateKeys: string[] = ['ean', 'brand', 'banner'];

const MaterialList: React.FC = () => {
    const { userid } = useContext(AuthContext);
    const { data: clients } = useInternalClientServiceGetInternalClient({});

    const [messageApi, contextHolder] = message.useMessage();
    const [selectedMaterial, setSelectedMaterial] = useState<MaterialViewDTO | undefined>(undefined);

    const [createOpen, setCreateOpen] = useState(false);
    const [approveOpen, setApproveOpen] = useState(false);

    const [query, setQuery] = useState("");
    const [debouncedQuery] = useDebounce(query, 500);
    const [selectedApprovalStatuses, setSelectedApprovalStatuses] = useState<number[]>([]);
    const [selectedStatuses, setSelectedStatuses] = useState<number[]>([]);
    const [selectedClients, setSelectedClients] = useState<number[]>([]);
    const [selectedUser, setSelectedUser] = useState<number | undefined>(undefined);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sortField, setSortField] = useState<string>("id");
    const [sortOrder, setSortOrder] = useState<"desc" | "asc">("desc");
    const [customFilters, setCustomFilters] = useState<Filter[]>([]);

    const filters = useMemo(() => {
        let filters: Filter[] = [];

        if (debouncedQuery) {
            filters.push({ type: 'search', values: [{value: debouncedQuery}], property: 'title,metadata.ean,metadata.product_range,metadata.product_type_primary,metadata.product_type_secondary,metadata.brand,metadata.banner,metadata.destination', name: 'title' });
        }

        if (selectedApprovalStatuses.length > 0) {
            filters.push({ type: 'select', values: selectedApprovalStatuses.map(e => ({value: e})), property: 'approvalStatus', name: 'approvalStatus' });
        }

        if (selectedStatuses.length > 0) {
            filters.push({ type: 'select', values: selectedStatuses.map(e => e === 1).map(e => ({value: e})), property: 'isEnabled', name: 'isEnabled' });
        }

        if (selectedClients.length > 0) {
            filters.push({ type: 'select', values: selectedClients.map(e => ({value: e})), property: 'clientId', name: 'clientId' });
        }

        if (selectedUser) {
            filters.push({ type: 'select', values: [{value: selectedUser}], property: 'producingArtistId', name: 'producingArtistId' });
        }

        for (let i = 0; i < customFilters.length; i++) {
            if(customFilters[i].values.length > 0){
                filters.push(customFilters[i]);
            }
        }

        return filters;
    }, [debouncedQuery, selectedApprovalStatuses, selectedStatuses, selectedClients, selectedUser, customFilters]);

    useEffect(() => {
        setPage(1);
    }, [debouncedQuery, selectedApprovalStatuses, selectedStatuses, selectedClients]);

    const { data, isFetching, refetch } = useMaterialServiceGetMaterial({
        top: pageSize.toString(),
        skip: ((page - 1) * pageSize).toString(),
        filter: GenerateODataFilter(filters),
        orderby: `${sortField} ${sortOrder}`,
        count: 'true'
    }, undefined, { refetchOnWindowFocus: false, placeholderData: keepPreviousData });
    const { mutateAsync: deleteMaterial } = useMaterialServiceDeleteMaterial();
    const { mutateAsync: editMaterial } = useMaterialServicePutMaterial();

    const handleCustomFilterAdd = useCallback(() => {
        setCustomFilters(v => [...v, { type: 'select', values: [], property: '', name: '' }]);
    }, []);
    const handleCustomFilterRemove = useCallback((index: number) => {
        setCustomFilters(v => v.filter((e,i) => i !== index));
    }, []);
    const handleCustomFilterChange = useCallback((value: Filter, index: number) => {
        setCustomFilters(v => v.map((e,i) => i === index ? value : e));
    }, []);


    const handleApprovalStatusChange = useCallback((value: any, option: { label: string; value: string; } | { label: string; value: string; }[]) => {
        if (Array.isArray(option)) {
            setSelectedApprovalStatuses(option.map(e => parseInt(e.value)));
        }
    }, []);

    const handleStatusChange = useCallback((value: any, option: { label: string; value: string; } | { label: string; value: string; }[]) => {
        if (Array.isArray(option)) {
            setSelectedStatuses(option.map(e => parseInt(e.value)));
        }
    }, []);

    const handleClientChange = useCallback((value: any, option: { label: string; value: string; } | { label: string; value: string; }[]) => {
        if (Array.isArray(option)) {
            setSelectedClients(option.map(e => parseInt(e.value)));
        }
    }, []);

    const handleUserChange = useCallback((value?: number) => {
        setSelectedUser(value);
    }, []);

    const openCreate = () => {
        setCreateOpen(true);
    };

    const handleCreateClose = useCallback(() => {
        setCreateOpen(false);
        refetch();
    }, [refetch]);

    const handleEditClose = useCallback(() => {
        setSelectedMaterial(undefined);
        refetch();
    }, [refetch]);

    const startApproval = () => {
        setApproveOpen(true);
    };

    const handleApprovalClose = useCallback(() => {
        setApproveOpen(false);
        refetch();
    }, [refetch]);

    const handleTableChange = useCallback((pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<MaterialViewDTO> | SorterResult<MaterialViewDTO>[]) => {
        setPage(pagination.current ?? 1);
        setPageSize(pagination.pageSize ?? 10);

        if (!Array.isArray(sorter)) {
            setSortField(sorter.field?.toString() ?? "id");
            setSortOrder(sorter.order === 'ascend' ? 'asc' : 'desc');
        }
    }, []);

    const handleDelete = useCallback((material: MaterialViewDTO) => {
        if (material.id) {
            deleteMaterial({ key: material.id }).then(() => {
                messageApi.success("Material deleted");
                refetch();
            })
        }
    }, [deleteMaterial, messageApi, refetch]);

    const handleEnable = useCallback((material: MaterialViewDTO) => {
        if (material.id) {
            editMaterial({ key: material.id, requestBody: { ...material, isEnabled: true } }).then(() => {
                messageApi.success("Material enabled");
                refetch();
            })
        }
    }, [editMaterial, messageApi, refetch]);

    const handleDisable = useCallback((material: MaterialViewDTO) => {
        if (material.id) {
            editMaterial({ key: material.id, requestBody: { ...material, isEnabled: false } }).then(() => {
                messageApi.success("Material disabled");
                refetch();
            })
        }
    }, [editMaterial, messageApi, refetch]);

    const showMyTasks = useCallback(() => {
        setSelectedUser(userid);
        setSelectedApprovalStatuses([AssetApprovalStatus.Pending, AssetApprovalStatus.InProduction, AssetApprovalStatus.Rejected]);
    }, [userid]);

    const columns: ColumnsType<MaterialViewDTO> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => (a.id || 0) - (b.id || 0),
        },
        {
            title: 'Thumbnail',
            dataIndex: 'thumbnail',
            key: 'title',
            render: (_, value) => (
                <Image
                    preview={false}
                    src={BASE_PATH + value.thumbnail}
                    height={100}
                />)
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => (a.title || '').localeCompare(b.title || ''),
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (_, value) => (value.isEnabled ? <CheckCircleOutlined style={{ color: '#389e0d' }} /> : <StopOutlined style={{ color: '#cf1322' }} />)
        },
        {
            title: 'Status',
            dataIndex: 'approvalStatus',
            key: 'approvalStatus',
            render: (_, value) => (AssetApprovalStatusOptions[value.approvalStatus as AssetApprovalStatus])
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            key: 'tags',
            render: (_, { tags }) => (<>{tags?.map((tag) => <Tag key={tag}>{tag}</Tag>)}</>),
        },
        {
            title: 'Metadata',
            dataIndex: 'metadata',
            key: 'metadata',
            width: 200,
            render: (_, { metadata }) => (<><Descriptions size='small' column={1} items={metadata?.filter(e => metadateKeys.includes(e.name.toLowerCase())).map(e => ({ label: e.name, children: e.value }))} /></>),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => setSelectedMaterial(record)}>Edit</Button>
                    {record.isEnabled ?
                        <Button type="link" onClick={() => handleDisable(record)}>Disable</Button> :
                        <Button type="link" onClick={() => handleEnable(record)}>Enable</Button>
                    }
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                        <Button type="link">Delete</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <>
            {contextHolder}

            <Flex justify='space-between' style={{ marginBottom: 16 }}>

                <Space>
                    <Input placeholder='search' value={query} onChange={e => setQuery(e.target.value)} />
                    <Select
                        mode="multiple"
                        placeholder="Client"
                        onChange={handleClientChange}
                        popupMatchSelectWidth={false}
                        size='middle'
                        style={{ minWidth: 100 }}
                        options={clients?.value.map(e => ({ label: e.name, value: e.id.toString() }))}
                    />
                    <Select
                        mode="multiple"
                        placeholder="Enabled"
                        onChange={handleStatusChange}
                        popupMatchSelectWidth={false}
                        size='middle'
                        style={{ minWidth: 100 }}
                        options={[
                            {
                                label: 'True',
                                value: '1'
                            },
                            {
                                label: 'False',
                                value: '0'
                            }
                        ]}
                    />
                    <Select
                        mode="multiple"
                        placeholder="Status"
                        onChange={handleApprovalStatusChange}
                        popupMatchSelectWidth={false}
                        value={selectedApprovalStatuses.map(e => e.toString())}
                        size='middle'
                        style={{ minWidth: 100 }}
                        options={[
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.Quote],
                                value: AssetApprovalStatus.Quote.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.Pending],
                                value: AssetApprovalStatus.Pending.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.InProduction],
                                value: AssetApprovalStatus.InProduction.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.Commited],
                                value: AssetApprovalStatus.Commited.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.New],
                                value: AssetApprovalStatus.New.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.Rejected],
                                value: AssetApprovalStatus.Rejected.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.RejectedPendingModification],
                                value: AssetApprovalStatus.RejectedPendingModification.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.RejectedButModified],
                                value: AssetApprovalStatus.RejectedButModified.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.Approved],
                                value: AssetApprovalStatus.Approved.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.ApprovedPendingModification],
                                value: AssetApprovalStatus.ApprovedPendingModification.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.ApprovedButModified],
                                value: AssetApprovalStatus.ApprovedButModified.toString()
                            }
                        ]}
                    />
                    <UserSelector placeholder="Assigned to" value={selectedUser} onChange={handleUserChange} style={{ width: 250 }} />
                </Space>

                <Space>
                    <Button type="primary" onClick={showMyTasks} icon={<SnippetsOutlined />}>
                        Show my tasks
                    </Button>
                    <Button type="primary" onClick={startApproval} icon={<CheckSquareOutlined />}>
                        Start approving
                    </Button>
                    <Button type="primary" onClick={openCreate} icon={<PlusOutlined />}>
                        New material
                    </Button>
                </Space>
            </Flex>

            <Space direction='vertical'>
                {customFilters.map((e,i) => <Space key={e.property}><MetadataSelector assetType='material' value={e} onChange={v => handleCustomFilterChange(v, i)} /><Button onClick={() => handleCustomFilterRemove(i)} icon={<CloseOutlined />}></Button></Space>)}
                <Button onClick={handleCustomFilterAdd} icon={<PlusOutlined />}>Filter</Button>
            </Space>



            <CreateMaterial isOpen={createOpen} onClose={handleCreateClose} />

            <EditMaterial isOpen={selectedMaterial != null} onClose={handleEditClose} material={selectedMaterial} />

            <Table
                loading={isFetching}
                dataSource={data?.value ?? Array.from({ length: pageSize }).map(x => ({}))}
                columns={columns}
                rowKey={"id"}
                onChange={handleTableChange}
                pagination={{
                    total: (data !== undefined && data['@odata.count']) ? data['@odata.count'] : 0,
                    current: page,
                    pageSize: pageSize,
                    pageSizeOptions: [10, 20, 50, 100],
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    showQuickJumper: true,
                    showSizeChanger: true,
                }} />

            <ApproveMaterial isOpen={approveOpen} materials={data?.value ?? []} onClose={handleApprovalClose} />
        </>
    );
};

export default MaterialList;
import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Drawer from 'react-modern-drawer';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_PATH } from "..";
import { ReactComponent as Cutout } from '../assets/icons/cutout.svg';
import { ReactComponent as InSitu } from '../assets/icons/in-situ.svg';
import { ReactComponent as LoadingIcon } from '../assets/icons/loading.svg';
import Layout from '../components/layout/Layout';
import CreateProject from "../components/project/CreateProject";
import { ProjectDetail } from '../components/project/ProjectDetail';
import { AuthContext } from "../contexts/AuthContext";
import { ConfigContext } from "../contexts/ConfigContext";
import { GenerateODataFilter } from "../helpers/odataFunctions";
import { useProjectServiceGetProject, useTemplateServiceGetTemplate } from "../openapi/queries";
import { ProjectDTO, TemplateViewDTO } from "../openapi/requests";
import './Landingpage.scss';
import ReactGA from "react-ga4";

const LoadingComponent = motion(LoadingIcon)

const Landingpage: React.FC = () => {
    const { hasPermission } = useContext(AuthContext);
    const { odataAssetFilters, odataFilters } = useContext(ConfigContext);
    const navigate = useNavigate();
    const [image, setImage] = useState("");
    const [selectedProject, setSelectedProject] = useState<ProjectDTO | undefined>(undefined);
    const [showProjectDetails, setShowProjectDetails] = useState(false);
    const { data: templates, isLoading: templatesLoading } = useTemplateServiceGetTemplate({ expand: 'scene', filter: GenerateODataFilter([...odataAssetFilters, {name: 'category', property: 'metadata.category', type: 'select', values: [{value: 'cut-out'}], negate: true}]), top: '5', orderby: 'id desc' });
    const { data: projects, isLoading: projectsLoading } = useProjectServiceGetProject({ filter: GenerateODataFilter([...odataFilters, { name: 'owner', property: 'IsOwner', type: 'select', values: [{value: true}] }]), orderby: 'timestampModify desc', top: '5' });
    const [formOpen, setFormOpen] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState<TemplateViewDTO | undefined>();

    const handleTemplateClick = useCallback((template: TemplateViewDTO) => {
        //navigate('/create/new/' + template.id);
        setSelectedTemplate(template);
        setFormOpen(true);
        
        
        ReactGA.event('click', {item: 'template', location: 'landingpage'});
    }, []);

    const handleTemplateHover = useCallback((template: TemplateViewDTO) => {
        setImage(BASE_PATH + template.thumbnail + "/1024");
    }, []);

    const handleProjectClick = useCallback((project: ProjectDTO) => {
        setSelectedProject(project);
        setShowProjectDetails(true);

        ReactGA.event('click', {item: 'project', location: 'landingpage'});

    }, []);

    useEffect(() => {
        if (templates && templates.value.length) {
            setImage(BASE_PATH + templates.value[0].thumbnail + "/1024");
        }
    }, [templates])

    const variants = {
        initial: {
            opacity: 0,
            clipPath: 'inset(0 0 100% 0)'
        },
        in: {
            opacity: 1,
            clipPath: 'inset(0 0 0 0)'
        },
        out: {
            opacity: 0
        }
    };

    return (<Layout className='landing-page'>
        <div className='landing-page-top'>
            {hasPermission("CreateOrders") && <>
                <h1>Let’s Start Creating</h1>
                <div>
                    <button className='animated' onClick={() => navigate('/create/new')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                            <g clipPath="url(#clip0_1395_744)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M16.5 33C25.6127 33 33 25.6127 33 16.5C33 7.3873 25.6127 0 16.5 0C7.3873 0 0 7.3873 0 16.5C0 25.6127 7.3873 33 16.5 33ZM20.6406 18.5117C18.375 20.2109 17.1836 22.3203 16.8906 24H16.0703C15.9336 23.1602 15.5625 22.2422 14.9375 21.2461C13.6875 19.2734 11.168 17.3398 9 16.8906V16.0703C10.0742 15.8359 11.1289 15.3477 12.1445 14.625C14.2148 13.1602 15.7383 10.9727 16.0703 9H16.8906C17.0859 10.0352 17.5352 11.0312 18.2383 12.0469C19.625 14.0586 21.793 15.543 24 16.0703V16.8906C22.8867 17.125 21.7539 17.6719 20.6406 18.5117Z" fill="#3D3D3D" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1395_744">
                                    <rect width="33" height="33" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span>New Project</span>
                        <div className='sub-menu'>
                            {(hasPermission("CreateOrders") && (hasPermission("FinalRender") || hasPermission("PriorityRender") || hasPermission("PreviewRender"))) &&
                                <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); navigate('/create/new') }}>
                                    <InSitu />
                                    <span>In-Situ</span>
                                </div>
                            }
                            {(hasPermission("CreateOrders") && (hasPermission("FinalRender") || hasPermission("PriorityRender"))) &&
                                <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); navigate('/create/cutouts') }}>
                                    <Cutout />
                                    <span>Cut-out</span>
                                </div>
                            }
                        </div>
                    </button>
                </div>
            </>}
        </div>
        <div className='landing-page-bottom'>
            {hasPermission("CreateOrders") && <>
                <div className='thumbnail'>
                    <AnimatePresence>
                        <motion.img
                            key={image}
                            src={image}
                            initial="initial"
                            animate="in"
                            exit="out"
                            variants={variants} />
                    </AnimatePresence>
                </div>
                <div className='list'>
                    <div className="list-header">
                        <h3>Newest Scenes</h3>
                        <Link to={'/create/new'} className='list-viewall'>See all</Link>
                    </div>
                    <div className='list-items'>

                        <AnimatePresence>
                            {(templatesLoading) && <LoadingComponent
                                className={'loading'}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                key={'loading'} />}
                        </AnimatePresence>

                        {templates?.value.map(template => <div key={template.id} className='list-item' onMouseEnter={() => handleTemplateHover(template)} onClick={() => handleTemplateClick(template)}>
                            <svg width="1000" height="2" viewBox="0 0 1000 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.5" d="M1000 1H0" stroke="black" strokeOpacity="0.6" strokeDasharray="2 2" />
                            </svg>
                            <span className='title' title={template.title}>
                                {template.title}
                            </span>
                            {template.tags.map(e => <span className='tag'>{e}</span>)}
                        </div>)}
                    </div>
                </div>
            </>}
            <div className='list'>
                <div className="list-header">
                    <h3>My projects</h3>
                    <Link to={'/create/projects'} className='list-viewall'>See all</Link>
                </div>
                <div className='list-items'>

                    <AnimatePresence>
                        {(projectsLoading) && <LoadingComponent
                            className={'loading'}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            key={'loading'} />}
                    </AnimatePresence>

                    {projects?.value.map((e: ProjectDTO) => <div key={e.id} className='list-item' onClick={() => handleProjectClick(e)}>
                        <svg width="1000" height="2" viewBox="0 0 1000 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.5" d="M1000 1H0" stroke="black" strokeOpacity="0.6" strokeDasharray="2 2" />
                        </svg>
                        <span className='title' title={e.name}>
                            {e.name}
                        </span>
                    </div>)}
                </div>
            </div>

            <Drawer className="project-detail-overlay" enableOverlay={false} open={showProjectDetails} size={"100%"} direction="bottom" onClose={() => setShowProjectDetails(false)}>
                <div className="project-detail-overlay-header">
                    <button onClick={() => setShowProjectDetails(false)}>
                        <svg width="56" height="28" viewBox="0 0 56 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="55" height="27" rx="13.5" fill="#1A1A1A" stroke="white" />
                            <path d="M30.5 10.5L23.5 17.5" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M23.5 10.5L30.5 17.5" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>
                <div className="project-detail-overlay-content">
                    {showProjectDetails ? <ProjectDetail projectId={selectedProject?.id} /> : null}
                </div>
            </Drawer>

            <CreateProject isOpen={formOpen} onCancel={() => setFormOpen(false)} template={selectedTemplate} />
        </div>
    </Layout>)
};

export default Landingpage;
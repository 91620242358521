import { PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Input, message, Popconfirm, Select, Space, Table } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { GenerateODataFilter } from '../../../helpers/odataFunctions';
import { AssetProjectStatus, AssetProjectStatusLabels } from '../../../models/enums';
import { useAssetProjectServiceDeleteAssetProject, useAssetProjectServiceGetAssetProject, useInternalClientServiceGetInternalClient } from '../../../openapi/queries';
import { AssetProjectViewDTO } from '../../../openapi/requests';
import { Filter } from '../../../types/types';
import CreateProject from './CreateAssetProject';
import { useNavigate } from 'react-router-dom';
import ReactTimeago from 'react-timeago';


const AssetProjectList: React.FC = () => {
    const { data: clients } = useInternalClientServiceGetInternalClient({});
    const {mutateAsync} = useAssetProjectServiceDeleteAssetProject();

    const [createOpen, setCreateOpen] = useState(false);
    const navigate = useNavigate();

    const [query, setQuery] = useState("");
    const [debouncedQuery] = useDebounce(query, 500);
    const [selectedApprovalStatuses, setSelectedApprovalStatuses] = useState<number[]>([]);
    const [selectedClients, setSelectedClients] = useState<number[]>([]);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sortField, setSortField] = useState<string>("id");
    const [sortOrder, setSortOrder] = useState<"desc" | "asc">("desc");

    const filters = useMemo(() => {
        let filters: Filter[] = [];

        if (debouncedQuery) {
            filters.push({ type: 'search', values: [{value: debouncedQuery}], property: 'title', name: 'title' });
        }

        if (selectedApprovalStatuses.length > 0) {
            filters.push({ type: 'select', values: selectedApprovalStatuses.map(e => ({value: e})), property: 'status', name: 'status' });
        }

        if (selectedClients.length > 0) {
            filters.push({ type: 'select', values: selectedClients.map(e => ({value: e})), property: 'clientId', name: 'clientId' });
        }

        return filters;
    }, [debouncedQuery, selectedApprovalStatuses, selectedClients]);

    useEffect(() => {
        setPage(1);
    }, [debouncedQuery, selectedApprovalStatuses, selectedClients]);

    const { data, isLoading, refetch } = useAssetProjectServiceGetAssetProject({
        top: '6',
        skip: ((page - 1) * pageSize).toString(),
        filter: GenerateODataFilter(filters),
        orderby: `${sortField} ${sortOrder}`
    })


    const handleApprovalStatusChange = useCallback((value: any, option: { label: string; value: string; } | { label: string; value: string; }[]) => {
        if (Array.isArray(option)) {
            setSelectedApprovalStatuses(option.map(e => parseInt(e.value)));
        }
    }, []);

    const handleClientChange = useCallback((value: any, option: { label: string; value: string; } | { label: string; value: string; }[]) => {
        if (Array.isArray(option)) {
            setSelectedClients(option.map(e => parseInt(e.value)));
        }
    }, []);

    const handleDelete = useCallback((project: AssetProjectViewDTO) => {
        mutateAsync({key: project.id}).then(() => {
            message.success("project deleted");
            refetch();
        });
    }, [mutateAsync, refetch]);

    const openCreate = () => {
        setCreateOpen(true);
    };

    const handleCreateClose = useCallback(() => {
        setCreateOpen(false);
        refetch();
    }, [refetch]);

    const handleTableChange = useCallback((pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<AssetProjectViewDTO> | SorterResult<AssetProjectViewDTO>[]) => {
        setPage(pagination.current ?? 1);
        setPageSize(pagination.pageSize ?? 10);

        if (!Array.isArray(sorter)) {
            setSortField(sorter.field?.toString() ?? "id");
            setSortOrder(sorter.order === 'ascend' ? 'asc' : 'desc');
        }
    }, []);

    const columns: ColumnsType<AssetProjectViewDTO> = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Client',
            dataIndex: 'client',
            key: 'client',
            render: (_, value) => "Kingfisher"
        },
        {
            title: 'Manager',
            dataIndex: 'userName',
            key: 'userName'
        },
        {
            title: 'Assets',
            dataIndex: 'assetCount',
            key: 'assetCount',
        },
        {
            title: 'Created',
            dataIndex: 'timestampCreate',
            key: 'timestampCreate',
            render: (_, value) => <ReactTimeago date={value.timestampCreate} />
        },
        {
            title: 'Status',
            dataIndex: 'approvalStatus',
            key: 'approvalStatus',
            render: (_, value) => AssetProjectStatusLabels[value.status as unknown as AssetProjectStatus]
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => {navigate('/admin/assetprojects/' + record.id)}}>Open</Button>
                    {record.workflowUrl && <Button type="link" onClick={() => window.open(record.workflowUrl!)}>Open in workflow</Button>}
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                        <Button danger type="link">Delete</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <>
            <Flex justify='space-between' style={{ marginBottom: 16 }}>

                <Space>
                    <Input placeholder='search' value={query} onChange={e => setQuery(e.target.value)} />
                    <Select
                        mode="multiple"
                        placeholder="Client"
                        onChange={handleClientChange}
                        popupMatchSelectWidth={false}
                        size='middle'
                        style={{ minWidth: 100 }}
                        options={clients?.value.map(e => ({ label: e.name, value: e.id.toString() }))}
                    />
                    <Select
                        mode="multiple"
                        placeholder="Status"
                        onChange={handleApprovalStatusChange}
                        popupMatchSelectWidth={false}
                        size='middle'
                        style={{ minWidth: 100 }}
                        options={[
                            {
                                label: AssetProjectStatusLabels[AssetProjectStatus.New],
                                value: AssetProjectStatus.New.toString()
                            },
                            {
                                label: AssetProjectStatusLabels[AssetProjectStatus.Quote],
                                value: AssetProjectStatus.Quote.toString()
                            },
                            {
                                label: AssetProjectStatusLabels[AssetProjectStatus.InProduction],
                                value: AssetProjectStatus.InProduction.toString()
                            }
                        ]}
                    />
                </Space>

                <Space>
                    <Button type="primary" onClick={openCreate} icon={<PlusOutlined />}>
                        New project
                    </Button>
                </Space>
            </Flex>

            <CreateProject isOpen={createOpen} onClose={handleCreateClose} />

            <Table
                loading={isLoading}
                dataSource={data?.value ?? Array.from({ length: pageSize }).map(x => ({}))}
                columns={columns}
                rowKey={"id"}
                onChange={handleTableChange}
                pagination={{
                    total: (data !== undefined && data['@odata.count']) ? data['@odata.count'] : 0,
                    current: page,
                    pageSize: pageSize,
                    pageSizeOptions: [10, 20, 50, 100],
                }} />
        </>
    );
};

export default AssetProjectList;
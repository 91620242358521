import { SelectProps, Select, Spin, Checkbox, Space } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAssetServiceGetAssetMetadataNames, useAssetServiceGetAssetMetadataValues1 } from "../../../openapi/queries";
import { Filter } from "../../../types/types";
import { CheckboxChangeEvent } from "antd/es/checkbox";


const formatLabel = (label: string) => {
    let encoded = encodeURIComponent(label);
    encoded = encoded.replaceAll(/(?<!^)%20(?!$)/g, " ");
    return encoded;
};


export const MetadataSelector: React.FC<SelectProps<Filter, DefaultOptionType> & {assetType?: string}> = (props) => {
    const [name, setName] = useState("");
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [negate, setNegate] = useState(false);

    const { data: names, isLoading: isNamesLoading } = useAssetServiceGetAssetMetadataNames({ type: props.assetType});
    const { data: values, isLoading: isValuesLoading } = useAssetServiceGetAssetMetadataValues1({name: name, type: props.assetType}, undefined, {enabled: !!name});

    const nameOptions = useMemo(() => {
        return names?.sort((a, b) => a.localeCompare(b)).map(e => ({ label: formatLabel(e), value: e }));
    }, [names]);

    const valueOptions = useMemo(() => {
        return values?.sort((a, b) => a.localeCompare(b)).map(e => ({ label: formatLabel(e), value: e }));
    }, [values]);

    useEffect(() => {
        setName(props.value?.name ?? "");
        setSelectedValues(props.value?.values.map(e => e.value as unknown as string) ?? []);
        setNegate(props.value?.negate ?? false);
    }, [props.value]);

    const handleNameChange = useCallback((value: string) => {
        setName(value);
        setSelectedValues([]);

        if(props.onChange){
            props.onChange({name: value, property: 'metadata.' + value, type: 'select', values: []}, {})
        }
    }, [props.onChange]);

    const handleValueChange = useCallback((value: string[]) => {
        setSelectedValues(value);

        if(props.onChange){
            props.onChange({name: name, property: 'metadata.' + name, type: 'select', negate, values: value.map(e =>({label: e, value: e}))}, {})
        }
    }, [name, props.onChange, negate]);

    const handleNegateChange = useCallback((e: CheckboxChangeEvent) => {
        setNegate(e.target.checked);
        
        if(props.onChange){
            props.onChange({name: name, property: 'metadata.' + name, type: 'select', negate: e.target.checked, values: selectedValues.map(e =>({label: e, value: e}))}, {})
        }
    }, [name, props.onChange, selectedValues]);

    return (
        <>
            <Space>
            <Select
                showSearch
                optionFilterProp="label"
                style={{ width: 250 }}
                onChange={handleNameChange}
                filterOption={true}
                value={name}
                notFoundContent={isNamesLoading ? <Spin size="small" /> : "No data"}
                options={nameOptions}
            />
            =
            <Select
                showSearch
                mode="multiple"
                optionFilterProp="label"
                style={{ width: 250 }}
                value={selectedValues}
                onChange={handleValueChange}
                filterOption={true}
                notFoundContent={isValuesLoading ? <Spin size="small" /> : "No data"}
                options={valueOptions}
            />
            <Checkbox checked={negate} onChange={handleNegateChange}>
                Not
            </Checkbox>
            </Space>
        </>
    );
}
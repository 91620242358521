import { format } from 'date-fns';
import saveAs from 'file-saver';
import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from "react-ga4";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BASE_PATH } from '../..';
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as Loading } from "../../assets/icons/loading.svg";
import { OrderlineFileType } from '../../models/enums';
import { useOrderlineServiceGetOrderOrderline1 } from '../../openapi/queries';
import { OrderlineFile, OrderlineViewDTO } from '../../openapi/requests';
import Turntable from '../layout/elements/Turntable';
import Overlay from '../layout/Overlay';
import './AssetDetails.scss';


interface Props {
    open: boolean;
    orderId: number | undefined | null;
    orderlineId: number | undefined | null;
    onClose: () => void;
}


const OrderlineDetails: React.FC<Props> = ({ open, orderId, orderlineId, onClose }) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const { data: orderline } = useOrderlineServiceGetOrderOrderline1({ key: orderId!, id: orderlineId! }, undefined, { enabled: open && !!orderId && !!orderlineId });

    useEffect(() => {
        if (open) {
            ReactGA.event('open_dialog', { dialog: 'OrderlineDetails' });
        }
    }, [open]);

    const onDownloadFile = (file: OrderlineFile) => {
        ReactGA.event('download_project_file', {});
        setIsDownloading(true);

        fetch(`${BASE_PATH}${file.url}`, {
            method: 'get',
            headers: {
                Accept: file.contentType,
                'Content-Type': file.contentType
            }
        }).then(response => {
            return response.blob();
        }).then(blob => {
            saveAs(blob, file.name);
            setIsDownloading(false);
        });
    }

    const onDownloadFiles = (file: OrderlineFile) => {
        ReactGA.event('download_project_files', {});

        setIsDownloading(true);

        let baseFileUrl = file.url.match(/.*(\/)/)!;

        fetch(`${BASE_PATH}${baseFileUrl[0]}all`, {
            method: 'get',
            headers: {
                Accept: file.contentType,
                'Content-Type': file.contentType
            }
        }).then(response => {
            return response.blob();
        }).then(blob => {
            saveAs(blob);
            setIsDownloading(false);
        });
    }
    
    const renderProjectFile = useCallback((orderline: OrderlineViewDTO) => {
        switch (orderline.fileType as unknown as OrderlineFileType) {
            case OrderlineFileType.Spin:
                return <>
                    <div className="orderline-img no-transition">
                        <Turntable sensitivity={0.3} images={orderline.files.map(file => `${BASE_PATH}${file.url + "/thumbnail/800/png"}`)} />
                    </div>
                    <div className="orderline-details">
                        <div className="project-images-list-name">
                            <span>{orderline.files[0].name}</span>
                        </div>
                        <div className="project-images-list-actions">
                            <button onClick={(e) => onDownloadFiles(orderline.files[0])}>
                                {isDownloading ? <div className="loading"><Loading /></div> : <Download />}
                            </button>
                        </div>
                        <div className="project-images-list-date">
                            <span>{format(new Date(orderline.files[0].timestampCreate ?? "1900-01-01"), "dd.MM.yyyy - pp")}</span>
                        </div>
                    </div>
                </>
            case OrderlineFileType.Animation:
            case OrderlineFileType.Clip:
                let file = orderline.files[0];
                return <>
                    <div className="orderline-img">
                        <video src={`${BASE_PATH}${file.url}`} controls />
                    </div>
                    <div className="orderline-details">
                        <div className="project-images-list-name">
                            <span>{orderline.files[0].name}</span>
                        </div>
                        <div className="project-images-list-actions">
                            <button onClick={(e) => onDownloadFiles(orderline.files[0])}>
                                {isDownloading ? <div className="loading"><Loading /></div> : <Download />}
                            </button>
                        </div>
                        <div className="project-images-list-date">
                            <span>{format(new Date(orderline.files[0].timestampCreate ?? "1900-01-01"), "dd.MM.yyyy - pp")}</span>
                        </div>
                    </div>
                </>
            case OrderlineFileType.Cutout:
            default: {
                let file = orderline.files[0];

                return <>
                    <div className="orderline-img">
                        <LazyLoadImage src={`${BASE_PATH}${file.url + "/thumbnail/1500/webp"}`} alt={file.name} />
                    </div>
                    <div className="orderline-details">
                        <div className="project-images-list-name">
                            <span>{file.name}</span>
                        </div>
                        <div className="project-images-list-actions">
                            <button onClick={(e) => onDownloadFile(file)}>
                                <Download />
                            </button>
                        </div>
                        <div className="project-images-list-date">
                            <span>{format(new Date(file.timestampCreate ?? "1900-01-01"), "dd.MM.yyyy - pp")}</span>
                        </div>
                    </div>
                </>
            }
        }
    }, [isDownloading])

    return (
        <>
            <Overlay open={open} onClose={onClose} className='asset-details orderline'>
                <div className="asset-details-overlay-content">
                    {orderline && renderProjectFile(orderline)}
                </div>
            </Overlay>
        </>
    );
};

export default OrderlineDetails;
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import { useDebouncedCallback } from "use-debounce";
import { BASE_PATH } from "../..";
import { ReactComponent as FacingLeftAngle } from "../../assets/icons/_c_01c.svg";
import { ReactComponent as StraightOnAngle } from "../../assets/icons/_c_02c.svg";
import { ReactComponent as SideAngle } from "../../assets/icons/_c_05c.svg";
import { ReactComponent as DetailShotAngle } from "../../assets/icons/_c_36c.svg";
import { ReactComponent as Spin360 } from "../../assets/icons/spin360.svg";
import "../layout/elements/Lists.scss";
import "./CutoutDetails.scss";
import CheckBox from "../layout/CheckBox";
import { CartContext, getRenderTemplates, OrderConfiguration } from "../../contexts/CartContext";
import { StillImageConfiguration, Camera, ModelPlacement, MaterialSelection, ModifierSelection } from "../../models/StillImageConfiguration";
import { genericAnglesIds, templateTypeIds } from "../../pages/CutoutsPage";
import { CombinedResultDTO, OrderPostProcessingFeatureDTO, SceneCameraDTO, SceneModifierTargetDTO, ScenePlacementpointDTO, SceneSurfaceDTO, TemplateViewDTO } from "../../openapi/requests";
import { useStillImageSubmissionPlanServiceGetStillImageSubmissionPlan, useTemplateServiceGetTemplate, useUserServiceGetUserRenderlimits } from "../../openapi/queries";
import { ConfigContext } from "../../contexts/ConfigContext";
import { GenerateODataFilter } from "../../helpers/odataFunctions";
import { Dialog } from "../layout/Dialog";
import { AuthContext } from "../../contexts/AuthContext";
import ReactGA from "react-ga4";
import { Loader } from "../layout/elements/Loader";
import { AssetTemplate, CutoutAngle, CutoutCategory } from "../../types/types";

export const CutoutDetails: React.FC<{ templates: TemplateViewDTO[], selectedAssets: CombinedResultDTO[], selectedAngles: CutoutAngle[], onAngleChange: (selectedAngle: CutoutAngle[]) => void, onDelete: (asset: CombinedResultDTO) => void, onSubmit: () => void }> = ({ templates, selectedAssets, selectedAngles, onAngleChange, onDelete, onSubmit }) => {

    const { userClient } = useContext(ConfigContext);
    const { hasPermission } = useContext(AuthContext);
    const { updateCutoutCurrentOrder, submitRendering, currentOrder, setLimitExceeded, submitting: cartSubmitting } = useContext(CartContext)
    const [limitDialogOpen, setLimitDialogOpen] = useState(false);

    //   const { data: templates } = useTemplateServiceGetTemplate({ expand: 'scene' }, undefined, { enabled: selectedAssets.length > 0 });
    const { data: stillImageSubmissionPlansData } = useStillImageSubmissionPlanServiceGetStillImageSubmissionPlan({ filter: GenerateODataFilter([{ name: 'client', property: 'clientid', values: [{value: userClient.id}], type: 'select' }]) });
    const { data: userRenderLimits } = useUserServiceGetUserRenderlimits(undefined, { enabled: userClient.id !== 0 })

    const availableTemplates = useMemo(() => {
        return templates.filter(e => e.editorModule.toLowerCase() === "cutout" || e.templatetype.internalName === "3dsmax_360spin") ?? [];
    }, [templates])

    const stillImageSubmissionPlans = useMemo(() => {
        return stillImageSubmissionPlansData?.value ?? [];
    }, [stillImageSubmissionPlansData])

    const [availableAngles, setAvailableAngles] = useState<CutoutAngle[]>([]);

    const getCameraTypeIcon = (id: string) => {

        switch (id.toLowerCase()) {
            case "c_01c":
                return <FacingLeftAngle />
            case "c_02c":
                return <StraightOnAngle />
            case "c_05c":
                return <SideAngle />
            case "c_36c":
                return <DetailShotAngle />
            case "c_01s":
                return <Spin360 />
        }
    }

    const formatAngleName = (title: string) => {
        return title.toLowerCase().replace("c_", "");
    }

    const formatCategoryName = (title: string) => {
        return title.replace("CoD_CutOut_", "")
    }

    const getDeliveryFileNameTemplate = useCallback((sceneCamera: SceneCameraDTO) => {
        let cameraName = formatAngleName(sceneCamera.cameraName)
        switch (cameraName) {
            //Spin360
            case "01s":
                return "{{SPINNO}}s";
            default:
                return cameraName;
        }
    }, [])

    const save = useCallback(() => {

        const mapModel = (placementPoint: ScenePlacementpointDTO, assets: CombinedResultDTO[]): ModelPlacement => {
            return {
                FeatureSelections: [],
                ModelSelections: [],
                SurfaceSelections: [],
                Name: placementPoint.name,
                Value: assets.filter(x => x.assetType.toLowerCase() === "model")[0].name,
                Values: assets.filter(x => x.assetType.toLowerCase() === "model").map(x => ({ value: x.name, filenamePrefix: x.metadata?.find(m => m.name.toLowerCase() === 'ean')?.value }))
            };
        };

        const mapMaterial = (surface: SceneSurfaceDTO, assets: CombinedResultDTO[]): MaterialSelection => {
            return {
                Name: surface.name,
                Value: '',
                Values: assets.filter(x => x.assetType.toLowerCase() === "material").map(x => ({ value: x.name, filenamePrefix: x.metadata?.find(m => m.name.toLowerCase() === 'ean')?.value }))
            }
        }

        const mapModifier = (modifiertarget: SceneModifierTargetDTO, assets: CombinedResultDTO[]): ModifierSelection => {
            return {
                Name: modifiertarget.name,
                Value: '',
                Values: assets.filter(x => x.assetType.toLowerCase() === "modifier").map(x => ({ value: x.name, filenamePrefix: x.metadata?.find(m => m.name.toLowerCase() === 'ean')?.value }))
            }
        }

        const assetTemplates: AssetTemplate[] = [];
        selectedAssets.forEach(asset => {
            const renderTemplates = getRenderTemplates(availableTemplates, asset);
            if (renderTemplates) {

                for (let i = 0; i < renderTemplates.length; i++) {
                    let renderTemplate = renderTemplates[i];
                    if (renderTemplate) {
                        const assetTemplate = assetTemplates.find(x => x.template.id === renderTemplate?.id);
                        if (assetTemplate) {
                            assetTemplate.assets = [...assetTemplate.assets, asset];
                        } else {
                            assetTemplates.push({ template: { ...renderTemplate }, assets: [asset] });
                        }
                    }

                }
            }
        });

        if (assetTemplates.length > 0) {

            const configurations: OrderConfiguration[] = [];

            var pathPostProcessFeature = assetTemplates[0].template.postProcessingFeatures.find(x => x.id === 12)
            var orderlinePostProcess = pathPostProcessFeature as OrderPostProcessingFeatureDTO;

            assetTemplates.forEach(assetTemplate => {

                let angles = selectedAngles.filter(x => x.template?.id === assetTemplate.template.id);

                const cameras: Camera[] = angles.map(angle => { return { Name: angle.id, Variants: [{ DeliveryFilenameTemplate: angle.deliveryFileNameTemplate, Lighting: undefined }] } as Camera });
                if (cameras.length > 0) {
                    let stillImageConfiguration: StillImageConfiguration = {} as StillImageConfiguration;

                    stillImageConfiguration = {
                        Cameras: cameras,
                        ModelSelections: assetTemplate.template.scene.placementpoints.map(x => mapModel(x, assetTemplate.assets)),
                        ModifierSelections: assetTemplate.template.scene.modifierTargets.map(x => mapModifier(x, assetTemplate.assets)),
                        SurfaceSelections: assetTemplate.template.scene.surfaces.map(x => mapMaterial(x, assetTemplate.assets)),
                        PropsetSelections: [],
                        camera: ''
                    }

                    configurations.push({ template: assetTemplate.template, configuration: stillImageConfiguration });
                }
            });

            updateCutoutCurrentOrder({ configurations: configurations, orderlinePostProcesses: [orderlinePostProcess] });
        }

    }, [availableTemplates, selectedAngles, selectedAssets, updateCutoutCurrentOrder]);

    const debouncedSave = useDebouncedCallback(save, 250);

    const handleRenderingSubmit = useCallback((plan: string) => {

        const selectedPlan = stillImageSubmissionPlans.find(e => e.name?.toLowerCase().includes(plan) && e.name?.toLowerCase().includes("cutout"));
        if (selectedPlan) {
            ReactGA.event('submit_render', { type: 'cutout' });

            //Validate limits
            let limitExceeded = false;

            if (currentOrder) {
                currentOrder?.configurations.forEach(config => {

                    if (limitExceeded) {
                        return;
                    }

                    let limitSubmissionPlan = stillImageSubmissionPlans.find(e => e.name?.toLowerCase().includes(plan) && e.name?.toLowerCase().includes("cutout"));

                    //Spin 360
                    let isSpin360 = (config.template.templatetype.internalName === "3dsmax_360spin");
                    if (isSpin360)
                        limitSubmissionPlan = stillImageSubmissionPlans.find(e => e.name?.toLowerCase().includes("spin360"));

                    if (limitSubmissionPlan) {
                        let userLimit = userRenderLimits.find(x => x.submissionPlan.id === limitSubmissionPlan?.id);
                        let maxLimit = limitSubmissionPlan.defaultRenderLimit;
                        if (userLimit) {
                            maxLimit = userLimit.limit;
                        }

                        let cameraCount = config.configuration.Cameras?.length ?? 0;
                        let modelCount = config.configuration.ModelSelections[0].Values?.length ?? 0;

                        limitExceeded = ((cameraCount * modelCount) > maxLimit);
                    }

                })
            }

            if (limitExceeded) {
                setLimitDialogOpen(true);
                return;
            }

            submitRendering(selectedPlan).then(() => {
                onSubmit();
            });

        } else {
            console.error("no suitable plan found");
        }

    }, [onSubmit, stillImageSubmissionPlans, submitRendering, userRenderLimits, currentOrder])

    useEffect(() => {

        let angles: CutoutAngle[] = [];
        selectedAssets.forEach(asset => {

            const renderTemplates = getRenderTemplates(availableTemplates, asset);
            if (renderTemplates) {

                for (let i = 0; i < renderTemplates.length; i++) {
                    let renderTemplate = renderTemplates[i];
                    if (renderTemplate) {

                        renderTemplate?.scene.cameras.forEach(cam => {

                            var angleValid = false;
                            if (renderTemplate?.templatetype.internalName === "3dsmax_360spin") {
                                if (cam.cameraName.toLowerCase() === "c_01s") {
                                    angleValid = true
                                }
                            }

                            if (renderTemplate?.templatetype.internalName === "3dsmax_still") {
                                if (cam.cameraName.toLowerCase() !== "c_01s") {
                                    angleValid = true
                                }
                            }

                            if (angleValid) {
                                let angle = { uniqueId: cam.cameraName.toLowerCase() + "|" + renderTemplate?.id, id: cam.cameraName, name: cam.label, template: renderTemplate, deliveryFileNameTemplate: getDeliveryFileNameTemplate(cam) } as CutoutAngle;
                                angles.push(angle);
                            }

                        });

                    }
                }
            }
        });

        setAvailableAngles(angles);

    }, [selectedAssets, availableTemplates, getDeliveryFileNameTemplate])

    const uniqueAngles = useMemo(() => {
        return availableAngles.filter(a => genericAnglesIds.includes(a.id.toLowerCase())).filter((value, index, arr) => arr.map(x => x.id).indexOf(value.id) === index);
    }, [availableAngles])

    const uniqueCategories = useMemo(() => {
        const angles = availableAngles.filter(a => a.template.templatetype.internalName !== "3dsmax_360spin" && !genericAnglesIds.includes(a.id.toLowerCase()) && !templateTypeIds.includes(a.id.toLowerCase()));
        const uniqueCategories = angles.map(x => x.template!).filter((value, index, arr) => arr.indexOf(value) === index);
        return uniqueCategories.map(x => { return { id: x.id, name: x.title, angles: angles.filter(a => a.template?.id === x.id).filter((value, index, arr) => arr.map(x => x.id).indexOf(value.id) === index) } as CutoutCategory })

    }, [availableAngles]);

    const uniqueTemplateTypes = useMemo(() => {
        return availableAngles.filter(a => a.template.templatetype.internalName !== "3dsmax_still" && templateTypeIds.includes(a.id.toLowerCase())).filter((value, index, arr) => arr.map(x => x.id).indexOf(value.id) === index);
    }, [availableAngles]);

    const onAngleSelectAll = (checked: boolean) => {

        let uniqueAvailableAngles: CutoutAngle[] = [];
        if (checked) {
            uniqueAvailableAngles = availableAngles.filter((value, index, arr) => { return arr.map(x => x.uniqueId).indexOf(value.uniqueId) === index });
        }
        onAngleChange(uniqueAvailableAngles);
        debouncedSave();
    }

    const onAngleSelect = (checked: boolean, angle: CutoutAngle) => {

        let angles: CutoutAngle[] = [];
        let newAngles: CutoutAngle[] = [];

        if (genericAnglesIds.includes(angle.id.toLowerCase()) || templateTypeIds.includes(angle.id.toLowerCase())) {
            angles = selectedAngles.filter(x => x.id.toLowerCase() !== angle.id.toLowerCase());
            newAngles = (checked) ? availableAngles.filter(x => x.id.toLowerCase() === angle.id.toLowerCase()) : [];
        } else {
            angles = selectedAngles.filter(x => !(x.id.toLowerCase() === angle.id.toLowerCase() && x.template?.id === angle.template?.id));
            newAngles = (checked) ? availableAngles.filter(x => x.id.toLowerCase() === angle.id.toLowerCase() && x.template?.id === angle.template?.id) : [];
        }

        angles = [...angles, ...newAngles];
        angles = angles.filter((value, index, arr) => { return arr.map(x => x.uniqueId).indexOf(value.uniqueId) === index })

        onAngleChange(angles);
        debouncedSave();
    }

    const onAssetRemove = useCallback((asset: CombinedResultDTO) => {
        onDelete(asset);
    }, [onDelete]);

    useEffect(() => {
        if (selectedAssets.length > 0) {
            debouncedSave();
        }
    }, [selectedAssets, debouncedSave])

    return (
        <div className="cutouts-detail">
            {cartSubmitting && <Loader></Loader>}

            <div className="cutouts-detail-wrapper">
                <div className="info-list cutout-detail-list cutout-detail-images">
                    <div className="info-list-header cutout-detail-list-header"><span>Selected Images</span></div>
                    <ul>
                        {selectedAssets.map(asset =>
                            <li key={asset.id}>
                                <span><img src={`${BASE_PATH + asset.thumbnail}`} alt={asset.title} />{asset.title}</span>
                                <span><button onClick={() => { onAssetRemove(asset); }}><Delete /></button></span>
                            </li>
                        )}
                    </ul>
                </div>
                <div className="info-list cutout-detail-list cutout-detail-angles">
                    <div className="info-list-header cutout-detail-list-header"><span>Angles</span></div>
                    <ul>{availableAngles.length > 0 ?
                        <>
                            <li>
                                <CheckBox label="Select All Angles" checked={false} onChange={onAngleSelectAll} />
                            </li>
                            <li>
                                <div className="cutout-angles">
                                    {uniqueAngles.filter(a => genericAnglesIds.includes(a.id.toLowerCase())).map(a =>
                                        <div className={`cutout-angle ${selectedAngles.map(x => x.id.toLowerCase()).includes(a.id.toLowerCase()) ? 'checked' : ''}`}>
                                            <CheckBox label={""} checked={selectedAngles.filter(sa => sa.id.toLowerCase() === a.id.toLowerCase() && sa.template?.id === a.template?.id).length > 0} onChange={(checked) => onAngleSelect(checked, a)}>
                                                <div>
                                                    <span>{getCameraTypeIcon(a.id)}</span>
                                                    <p>
                                                        <span>{formatAngleName(a.id)}</span>
                                                        <span>{a.name}</span>
                                                    </p>
                                                </div>
                                            </CheckBox>
                                        </div>
                                    )}
                                </div>
                            </li>
                            {uniqueCategories.map(category =>
                                <>
                                    <li className="info-list-header cutout-detail-list-category">
                                        {formatCategoryName(category.name)}
                                    </li>
                                    {category.angles.map(a =>
                                        <li>
                                            <CheckBox label={""} checked={selectedAngles.filter(sa => sa.id.toLowerCase() === a.id.toLowerCase() && sa.template?.id === a.template?.id).length > 0} onChange={(checked) => onAngleSelect(checked, a)}>
                                                <div>
                                                    <span>{formatAngleName(a.id)}</span>
                                                    <span>{a.name}</span>
                                                </div>
                                            </CheckBox>
                                        </li>
                                    )}
                                </>
                            )}
                            <li>
                                <div className="cutout-templatetypes">
                                    {uniqueTemplateTypes.filter(a => templateTypeIds.includes(a.id.toLowerCase())).map(a =>
                                        <div className={`cutout-templatetype ${selectedAngles.map(x => x.id.toLowerCase()).includes(a.id.toLowerCase()) ? 'checked' : ''}`}>
                                            <CheckBox label={""} checked={selectedAngles.filter(sa => sa.id.toLowerCase() === a.id.toLowerCase() && sa.template?.id === a.template?.id).length > 0} onChange={(checked) => onAngleSelect(checked, a)}>
                                                <div>
                                                    <span>{getCameraTypeIcon(a.id)}</span>
                                                    <p>
                                                        <span>{formatAngleName(a.id)}</span>
                                                        <span>{a.name}</span>
                                                    </p>
                                                </div>
                                            </CheckBox>
                                        </div>
                                    )}
                                </div>
                            </li>

                        </>
                        : <li className="noangles">No availble angles...</li>}
                    </ul>
                </div>
            </div>
            <div className="cutouts-detail-actions">
                <div>
                    {hasPermission("CreateOrders") && (hasPermission('FinalRender')) && <button className='render' disabled={selectedAngles.length === 0} onClick={() => handleRenderingSubmit("final")}>Final render</button>}
                    {hasPermission("CreateOrders") && (hasPermission('PriorityRender')) && <button className='render' disabled={selectedAngles.length === 0} onClick={() => handleRenderingSubmit("priority")}>Priority render</button>}
                </div>
            </div>

            <Dialog open={limitDialogOpen} className='confirm'>
                <h1>Renderlimit Exceeded</h1>
                <p className='confirm-body'>
                    You have exceeded the amount of renders available to you per order, please modify your order.
                </p>
                <div className='confirm-footer'>
                    <button className='confirm' onClick={() => { setLimitExceeded(false); setLimitDialogOpen(false) }}>Ok</button>
                </div>
            </Dialog>
        </div>
    )
}

export default CutoutDetails;
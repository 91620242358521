import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ReactComponent as GridIcon } from '../assets/icons/grid.svg';
import { ReactComponent as ListIcon } from '../assets/icons/list.svg';
import TemplateDetails from '../components/designer/TemplateDetails';
import AssetFilter from '../components/layout/AssetFilter';
import AssetList from '../components/layout/AssetList';
import Layout from '../components/layout/Layout';
import CreateProject from '../components/project/CreateProject';
import { ConfigContext } from '../contexts/ConfigContext';
import { GenerateODataFilter } from '../helpers/odataFunctions';
import { useTemplateServiceGetTemplate } from '../openapi/queries';
import { TemplateViewDTO } from '../openapi/requests';
import { Filter } from '../types/types';
import './TemplateCategories.scss';
import ReactGA from "react-ga4";

interface TemplateAsset extends TemplateViewDTO {
    name: string;
    title: string;
    thumbnail: string;
}

const TemplateCategories: React.FC = () => {
    const { filters, odataAssetFilters } = useContext(ConfigContext);
    const [mode, setMode] = useState<"medium" | "list">("medium");
    const [selectedFilters, setSelectedFilters] = useState<Filter[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<TemplateAsset | undefined>();
    const [showDetails, setShowDetails] = useState(false);
    const { data: templates, isLoading: templatesLoading } = useTemplateServiceGetTemplate({ expand: 'scene', filter: GenerateODataFilter([...odataAssetFilters, {name: 'category', property: 'metadata.category', type: 'select', values: [{value: 'cut-out'}], negate: true}])});
    const [formOpen, setFormOpen] = useState(false);

    const filteredTemplates = useMemo(() => {
        let filtered = templates?.value || [];

        for (let i = 0; i < selectedFilters.length; i++) {
            const filter = selectedFilters[i];

            if (filter.values.length > 0) {

                ReactGA.event('template_filter', {
                    field: filter.property,
                });

                if (filter.type === "search") {
                    let searchValues = filter.values[0].toString().split(",");
                    searchValues = searchValues.map(x => x.trim());

                    filtered = filtered.filter(x => {
                        return x.metadata.some(
                            m => searchValues.some(e => e.toLowerCase() === m.value.toLowerCase())
                        ) || x.tags.some(
                            t => searchValues.some(e => e.toLowerCase() === t.toLowerCase())
                        ) || searchValues.some(e => x.title.toLowerCase().indexOf(e.trim().toLowerCase()) >= 0)
                    });
                } else {
                    filtered = filtered.filter((e) => {
                        return e.metadata.some(t =>
                            t.name.toLowerCase() === filter.property.toLowerCase() &&
                            filter.values.some(e => e.toString().toLowerCase() === t.value.toLowerCase())
                        )
                    });
                }
            }
        }

        return filtered.map(e => ({
            ...e,
            name: e.id.toString(),
            title: e.title,
            thumbnail: e.scene.cameras.find(e => e.aspectRatio === 1)?.thumbnail || e.scene.cameras[0]?.thumbnail || e.scene.thumbnail,
        }));
    }, [selectedFilters, templates]);

    const gotoTemplate = (template: TemplateAsset) => {

        ReactGA.event('click', {item: 'template', location: 'templates'});

        setSelectedTemplate(template); 
        setFormOpen(true)
    }

    const onItemInfoClick = useCallback((template: TemplateAsset) => {
        setSelectedTemplate(template); 
        setShowDetails(true)
    }, []);

    return (<Layout className='categories'>
        <header>
            <div>

            </div>
            <div>
                <button className={(mode === "medium") ? 'selected' : 'deselected'} onClick={() => setMode("medium")}>
                    <GridIcon />
                </button>
                <button className={(mode === "list") ? 'selected' : 'deselected'} onClick={() => setMode("list")}>
                    <ListIcon />
                </button>
            </div>
            <div className='header-filter'>
            </div>
        </header>

        <div className='categories-content'>
            <h1>All<span>({filteredTemplates.length})</span></h1>

            <AssetFilter filters={selectedFilters} 
                onChange={e => setSelectedFilters(e)} 
                isOpen={true} 
                items={templates?.value.map(e => e.scene) || []} 
                properties={filters.templates.fields.map(e => ({
                    name: e.label,
                    property: e.fields[0].replace("metadata.", ""),
                    type: e.type,
                }))} />

            <AssetList
                loading={templatesLoading}
                assets={filteredTemplates}
                onItemClick={gotoTemplate} 
                onItemInfoClick={onItemInfoClick}
                displayMode={mode} />
        </div>

        <CreateProject isOpen={formOpen} onCancel={() => setFormOpen(false)} template={selectedTemplate} />

        <TemplateDetails open={showDetails} onClose={() => setShowDetails(false)} templateId={selectedTemplate?.id} />
    </Layout>)
};

export default TemplateCategories;
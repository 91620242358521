import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Drawer, Empty, Form, Image, Input, Radio, Segmented, Select, SelectProps, Space, Spin, message } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useInternalClientServiceGetInternalClient, useInternalTagServiceGetInternalTag, useModelPackServiceGetModelPackQAimages, useModelPackServicePutModelPack, useModelServiceGetModel, useModelServiceGetModel1 } from '../../../openapi/queries';
import { ModelPackViewDTO } from '../../../openapi/requests';
import ImageFileUpload from '../Inputs/ImageFileUpload';
import { BASE_PATH } from '../../..';
import { AssetApprovalStatus, AssetApprovalStatusOptions, AssetSourceType } from '../../../models/enums';
import { DefaultOptionType } from 'antd/es/select';
import { useDebounce } from 'use-debounce';
import { GenerateODataFilter } from '../../../helpers/odataFunctions';
import { UserSelector } from '../Inputs/UserSelector';
import CopyToClipboard from 'react-copy-to-clipboard';
import { AssetSelector } from '../Inputs/AssetSelector';

const ModelSelector: React.FC<SelectProps<number, DefaultOptionType>> = (props) => {
    const [value, setValue] = useState("");
    const [debouncedValue] = useDebounce(value, 1000);
    
    const { data: model } = useModelServiceGetModel1({
        key: props.value ?? 0
    });

    const { data, isLoading } = useModelServiceGetModel({
        top: '50',
        filter: GenerateODataFilter([{ type: 'search', values: [{value: debouncedValue}], property: 'title,metadata.ean,metadata.product_type_primary,metadata.product_type_secondary', name: 'title' }]),
    }, undefined, {enabled: debouncedValue.length > 0});

    return (
        <Select
            showSearch
            filterOption={false}
            onSearch={setValue}
            notFoundContent={isLoading ? <Spin size="small" /> : "No matches"}
            {...props}
            options={[{label: model?.title, value: model?.id}, ...(data?.value.map(e => ({ label: e.title, value: e.id })) ?? [])].filter(e => e.value !== undefined)}
        />
    );
}

const EditModelpack: React.FC<{ modelpack?: ModelPackViewDTO, isOpen: boolean, onClose: () => void }> = ({ modelpack, isOpen, onClose }) => {
    const { data: clients } = useInternalClientServiceGetInternalClient({}, undefined, { enabled: isOpen });
    const { data: alltags } = useInternalTagServiceGetInternalTag({}, undefined, { enabled: isOpen });
    const { data: qaImages } = useModelPackServiceGetModelPackQAimages({ key: modelpack?.id ?? 0 }, undefined, { enabled: isOpen && modelpack !== undefined });
    const { mutateAsync, isPending } = useModelPackServicePutModelPack();

    const [form] = Form.useForm<ModelPackViewDTO>();
    const [messageApi, contextHolder] = message.useMessage();

    const [modelPackImage, setModelPackImage] = useState("");
    const source = Form.useWatch('sourceType', form);
    const sourcePath = Form.useWatch('sourcePath', form);

    const tags = useMemo(() => {
        if (alltags) {
            const tags = Array.from(new Set(alltags.value.map(e => e.value)));
            tags.sort();
            return tags;
        }
        return [];
    }, [alltags]);

    const onSubmit = () => {
        form.submit();
    }

    const getThumbnailPath = useCallback(() => {
        return `${BASE_PATH}/modelpack/${modelpack?.id}/thumbnail?rnd=` + Math.floor(Math.random() * 999999999)
    }, [modelpack]);

    const onUpload = (info: UploadChangeParam) => {
        if (modelpack) {
            setModelPackImage(getThumbnailPath())
        }
    }

    const onFinish = () => {
        if (modelpack && modelpack.id) {

            const values = form.getFieldsValue(true) as ModelPackViewDTO;

            mutateAsync({ key: modelpack.id, requestBody: values })
                .then(() => {
                    messageApi.success("Model pack updated");
                    onClose();

                }).catch(reason => {
                    messageApi.error(JSON.stringify(reason));
                });
        }
    };

    useEffect(() => {
        if (modelpack) {
            setModelPackImage(getThumbnailPath())
            form.setFieldsValue({...modelpack, metadata: [...modelpack.metadata].sort((a,b) => a.name.localeCompare(b.name))});
        }
    }, [modelpack, form, getThumbnailPath]);

    return (
        <>
            {contextHolder}

            <Drawer
                title={`Edit ${modelpack?.title}`}
                width={720}
                onClose={onClose}
                open={isOpen}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={isPending}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}>

                    <Form.Item rules={[{ required: true }]} name='clientId' label="Client">
                        <Select options={clients?.value.map(e => ({ label: e.name, value: e.id }))} />
                    </Form.Item>

                    <Form.Item label="Thumbnail">
                        <Space direction='vertical'>
                            <ImageFileUpload title="Upload thumbnail" action={`${BASE_PATH}/modelpack/${modelpack?.id}/image`} onUpload={onUpload} />
                            <Image placeholder="" preview={false} width={300} src={modelPackImage} />
                        </Space>
                    </Form.Item>

                    <Form.Item label="QA Images">
                        <Space wrap>
                            {qaImages?.map(img => <Image key={img} placeholder="" width={150} src={BASE_PATH + img} />)}
                            {qaImages?.length === 0 && <Empty />}
                        </Space>
                    </Form.Item>

                    <Form.Item name="isEnabled" valuePropName="checked">
                        <Checkbox>Enabled</Checkbox>
                    </Form.Item>

                    <Form.Item name="approvalStatus" label="Status">
                            <Segmented vertical
                                options={[
                                    { label: AssetApprovalStatusOptions[AssetApprovalStatus.Quote], value: AssetApprovalStatus.Quote, disabled: true },
                                    { label: AssetApprovalStatusOptions[AssetApprovalStatus.Pending], value: AssetApprovalStatus.Pending, disabled: true },
                                    { label: AssetApprovalStatusOptions[AssetApprovalStatus.InProduction], value: AssetApprovalStatus.InProduction },
                                    { label: AssetApprovalStatusOptions[AssetApprovalStatus.Commited], value: AssetApprovalStatus.Commited },
                                    { label: AssetApprovalStatusOptions[AssetApprovalStatus.New], value: AssetApprovalStatus.New },
                                    { label: AssetApprovalStatusOptions[AssetApprovalStatus.Rejected], value: AssetApprovalStatus.Rejected },
                                    { label: AssetApprovalStatusOptions[AssetApprovalStatus.RejectedButModified], value: AssetApprovalStatus.RejectedButModified, disabled: true },
                                    { label: AssetApprovalStatusOptions[AssetApprovalStatus.RejectedPendingModification], value: AssetApprovalStatus.RejectedPendingModification, disabled: true },
                                    { label: AssetApprovalStatusOptions[AssetApprovalStatus.Approved], value: AssetApprovalStatus.Approved },
                                    { label: AssetApprovalStatusOptions[AssetApprovalStatus.ApprovedButModified], value: AssetApprovalStatus.ApprovedButModified, disabled: true },
                                    { label: AssetApprovalStatusOptions[AssetApprovalStatus.ApprovedPendingModification], value: AssetApprovalStatus.ApprovedPendingModification, disabled: true },
                                ]}
                            />
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name='title' label="Title">
                        <Input />
                    </Form.Item>

                    <Form.Item name='producingArtistId' label="Artist">
                        <UserSelector />
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name='complexity' label="Complexity" >
                        <Radio.Group
                            options={[
                                { label: 'Simple', value: 0 },
                                { label: 'Complex', value: 5 },
                                { label: 'Very complex', value: 10 }
                            ]}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name='sourceType' label="Source" >
                        <Radio.Group
                            options={[
                                { label: 'Unknown', value: 0 },
                                { label: 'Existing CoD asset', value: 1 },
                                { label: 'Workflow', value: 2 },
                                { label: 'None', value: 3 },
                                { label: 'CAD File', value: 4 },
                                { label: 'Max File', value: 5 }
                            ]}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </Form.Item>

                    {source === AssetSourceType.Workflow &&
                        <Form.Item label="Workflow asset">
                            <Space.Compact style={{ width: '100%' }}>
                                <Form.Item name='sourcePath' style={{ flexGrow: 1 }} noStyle>
                                    <Input addonBefore="http://" />
                                </Form.Item>
                                <Button type="default" onClick={() => window.open(sourcePath ?? "")}>Open</Button>
                            </Space.Compact>
                        </Form.Item>}

                    {source === AssetSourceType.ExistingAsset &&
                        <Form.Item label="Source model" name="sourcePath">
                            <AssetSelector type='Modelpack' />
                        </Form.Item>}

                    {(source === AssetSourceType.CADFile || source === AssetSourceType.MaxFile) &&
                        <Form.Item label="Source file folder">
                            <Space.Compact style={{ width: '100%' }}>
                                <Form.Item name='sourcePath' style={{ flexGrow: 1 }} noStyle>
                                    <Input />
                                </Form.Item>
                                <CopyToClipboard text={sourcePath ?? ""}>
                                    <Button type="default">Copy path</Button>
                                </CopyToClipboard>
                            </Space.Compact>
                        </Form.Item>
                    }

                    <Form.Item name='notes' label="Notes">
                        <Input.TextArea rows={3} />
                    </Form.Item>

                    <Form.Item name='buildScript' label="build Script">
                        <Input />
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name='name' label="Name">
                        <Input />
                    </Form.Item>

                    <Form.Item name='tags' label="Tags">
                        <Select mode='tags' options={tags.map(e => ({ label: e, value: e }))} />
                    </Form.Item>

                    <Form.Item label='Metadata'>
                        <Form.List name="metadata">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex' }} align="baseline">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'name']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="name" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'value']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="value" />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add metadata
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>


                    <Form.Item label='Models'>
                        <Form.List name="models">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space.Compact key={key} style={{ display: 'flex', alignItems: 'baseline', gap: 5 }}>
                                            <Form.Item
                                                {...restField}
                                                style={{ flexGrow: 1 }}
                                                name={[name, 'isMasterModel']}
                                                valuePropName="checked"
                                                initialValue={false}
                                                rules={[{ required: true }]}
                                            >

                                                <Checkbox>Master</Checkbox>
                                            </Form.Item>
                                            <Form.Item
                                                style={{ flexGrow: 10, width: 100 }}
                                                {...restField}
                                                name={[name, 'modelId']}
                                                rules={[{ required: true }]}
                                            >
                                                <ModelSelector />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space.Compact>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add model
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                </Form>
            </Drawer>
        </>

    );
};

export default EditModelpack;
import { StopOutlined } from '@ant-design/icons';
import { Input, Space, Table } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { GenerateODataFilter } from '../../../helpers/odataFunctions';
import { useProjectServiceGetProject } from '../../../openapi/queries';
import { ProjectDTO } from '../../../openapi/requests';
import { Filter } from '../../../types/types';
import TimeAgo from 'react-timeago';

const ProjectList: React.FC = () => {

    const [query, setQuery] = useState("");
    const [debouncedQuery] = useDebounce(query, 500);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sortField, setSortField] = useState<string>("id");
    const [sortOrder, setSortOrder] = useState<"desc" | "asc">("desc");

    const filters = useMemo(() => {
        let filters: Filter[] = [];

        if (debouncedQuery) {
            filters.push({type: 'search', values: [{value: debouncedQuery}], property: 'name', name: 'name'});
        }

        return filters;
    }, [debouncedQuery]);

    const { data: projects, isLoading } = useProjectServiceGetProject({ 
        top: pageSize.toString(), 
        skip: ((page - 1) * pageSize).toString(),
        filter: GenerateODataFilter(filters),
        orderby: `${sortField} ${sortOrder}`,
        count: 'true' });

    const handleTableChange = useCallback((pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<ProjectDTO> | SorterResult<ProjectDTO>[]) => {
        setPage(pagination.current ?? 1);
        setPageSize(pagination.pageSize ?? 10);

        if(!Array.isArray(sorter)){
            setSortField(sorter.field?.toString() ?? "id");
            setSortOrder(sorter.order === 'ascend' ? 'asc' : 'desc');
        }
    }, []);

    const columns: ColumnsType<ProjectDTO> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => (a.id || 0) - (b.id || 0),
        },
        {
            title: 'Title',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
        },
        {
            title: 'Deleted',
            dataIndex: 'deleted',
            key: 'deleted',
            render: (_, value) => (value.isEnabled ? <StopOutlined style={{color: '#cf1322'}} /> : null)
        },
        {
            title: 'Created at',
            dataIndex: 'timestampCreate',
            key: 'created',
            render: (_, value) => <TimeAgo date={value.timestampCreate} />
        },
        {
            title: 'Modified at',
            dataIndex: 'timestampModify',
            key: 'modified',
            render: (_, value) => <TimeAgo date={value.timestampModify} />
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Link to={'/admin/projects/' + record.id}>View</Link>
                </Space>
            ),
        },
    ];

    return (
        <>
            <Space style={{marginBottom: 16}}>
                <Input placeholder='search' value={query} onChange={e => setQuery(e.target.value)} />
            </Space>

            <Table
                loading={isLoading}
                dataSource={projects?.value ?? Array.from({ length: pageSize }).map(x => ({}))}
                columns={columns}
                rowKey={"id"}
                onChange={handleTableChange}
                pagination={{
                    total: (projects !== undefined && projects['@odata.count']) ? projects['@odata.count'] : 0,
                    current: page,
                    pageSize: pageSize,
                }} />
        </>
    );
};

export default ProjectList;
import React, { useEffect } from 'react';
import ReactGA from "react-ga4";
import { BASE_PATH } from '../..';
import { AssetSearchResultDTO } from '../../openapi/requests';
import Overlay from '../layout/Overlay';
import './AssetDetails.scss';

interface Props {
    open: boolean;
    asset?: AssetSearchResultDTO;
    onClose: () => void;
}

const WorkflowAssetDetails: React.FC<Props> = ({ open, asset, onClose }) => {

    useEffect(() => {
        if (open) {
            ReactGA.event('open_dialog', { dialog: 'workflowassetdetails' });
        }
    }, [open]);

    return (
        <>
            <Overlay open={open} onClose={onClose} className='asset-details'>
                <div className="asset-detail-header"><h1>{asset?.title}</h1></div>
                <div className="asset-details-overlay-content">
                    <div className='meta info-list'>
                        <div className="info-list-header"><span>Details</span></div>
                        <ul>
                            {asset?.link &&  <li key="workflowlink"><span></span><span><a target='_blank' rel="noreferrer" href={asset.link}>Open in workflow</a></span></li>}
                            {asset?.metadata.map(e => <li key={e.name + e.value}><span>{e.name}</span><span>{e.value}</span></li>)}
                        </ul>
                    </div>
                    <div className='images info-list'>
                        <div className="info-list-header"><span>Asset</span></div>
                        {asset && <img src={BASE_PATH + asset.thumbnail} alt={asset.title} />}
                    </div>
                </div>
            </Overlay>
        </>
    );
};

export default WorkflowAssetDetails;
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { Button, Input, Popconfirm, Space, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CreateTemplate from './CreateUser';
import EditTemplate from './EditUser';
import { useUserServiceDeleteUser, useUserServiceGetUser, useUserServiceGetUserKey } from '../../../openapi/queries';
import { UserViewDTO } from '../../../openapi/requests';
import { useDebounce } from 'use-debounce';
import { Filter } from '../../../types/types';
import { GenerateODataFilter } from '../../../helpers/odataFunctions';
import { FilterValue, SorterResult } from 'antd/es/table/interface';

const UserList: React.FC = () => {

    const [messageApi, contextHolder] = message.useMessage();
    const [query, setQuery] = useState("");
    const [debouncedQuery] = useDebounce(query, 500);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sortField, setSortField] = useState<string>("id");
    const [sortOrder, setSortOrder] = useState<"desc" | "asc">("desc");
    const [selectedUser, setSelectedUser] = useState<UserViewDTO | undefined>(undefined);

    const [createOpen, setCreateOpen] = useState(false);

    const {mutate, isSuccess} = useUserServiceDeleteUser();

    const filters = useMemo(() => {
        let filters: Filter[] = [];

        if (debouncedQuery) {
            filters.push({type: 'search', values: [{value: debouncedQuery}], property: 'username', name: 'username'});
        }

        return filters;
    }, [debouncedQuery]);

    const { data, isLoading, refetch } = useUserServiceGetUser({
        top: pageSize.toString(),
        skip: ((page - 1) * pageSize).toString(),
        filter: GenerateODataFilter(filters),
        orderby: `${sortField} ${sortOrder}`,
        count: 'true' })
    
    const openCreate = () => {
        setCreateOpen(true);
    };

    const handleCreateClose = useCallback(() => {
        setCreateOpen(false);
        refetch();
    }, [refetch]);

    const handleEditClose = useCallback(() => {
        setSelectedUser(undefined);
        refetch();
    }, [refetch]);

    const handleEditUpdate = useCallback(() => {
        refetch();
    }, [refetch])

    const handleTableChange = useCallback((pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<UserViewDTO> | SorterResult<UserViewDTO>[]) => {
        setPage(pagination.current ?? 1);
        setPageSize(pagination.pageSize ?? 10);

        if(!Array.isArray(sorter)){
            setSortField(sorter.field?.toString() ?? "id");
            setSortOrder(sorter.order === 'ascend' ? 'asc' : 'desc');
        }
    }, []);

    const handleDelete = useCallback((user: UserViewDTO) => {
        if(user.id){
            mutate({key: user.id})
        }
    }, [mutate]);

    useEffect(() => {
        if(isSuccess){
            messageApi.success("User deleted");
        }
    }, [isSuccess, messageApi]);

    const columns: ColumnsType<UserViewDTO> = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
            sorter: (a, b) => (a.id || 0) - (b.id || 0),
        },
        {
            title: "Username",
            dataIndex: "username",
            key: "username",
            sorter: (a, b) => (a.username || '').localeCompare(b.username || ''),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => setSelectedUser(record)}>Edit</Button>
                    <Popconfirm title="Sure to delete?" disabled={!record.isDeleteable} onConfirm={() => handleDelete(record)}>
                        <Button type="link" disabled={!record.isDeleteable}>Delete</Button>
                    </Popconfirm>
                </Space>
            ),
        }  
    ];

    return (
        <>
            {contextHolder}

            <Space style={{marginBottom: 16}}>
                <Button type="primary" onClick={openCreate} icon={<PlusOutlined />}>New user</Button>
                <Input placeholder='search' value={query} onChange={e => setQuery(e.target.value)} />
            </Space>

            <CreateTemplate isOpen={createOpen} onClose={handleCreateClose} />

            <EditTemplate isOpen={selectedUser != null} onClose={handleEditClose} onUpdate={handleEditUpdate} user={selectedUser} />

            <Table
                loading={isLoading}
                dataSource={data?.value ?? Array.from({ length: pageSize }).map(x => ({}))}
                columns={columns}
                rowKey={"id"} 
                onChange={handleTableChange}
                pagination={{
                    total: (data !== undefined && data['@odata.count']) ? data['@odata.count'] : 0,
                    current: page,
                    pageSize: pageSize,
                }} />
        </>
    );
}

export default UserList;
import { EditOutlined, ExportOutlined, ImportOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Input, message, Popconfirm, Select, Space, Table } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { GenerateODataFilter } from '../../../helpers/odataFunctions';
import { AssetApprovalStatus, AssetApprovalStatusLabels, AssetApprovalStatusOptions, AssetSourceType, AssetSourceTypeLabels } from '../../../models/enums';
import { useAssetProjectServiceGetAssetProject1, useAssetProjectServiceGetAssetProjectAssets, useAssetProjectServicePostAssetProjectScan, useAssetProjectServicePutAssetProjectAssets, useMaterialServiceDeleteMaterial, useModelPackServiceDeleteModelPack, useModelServiceDeleteModel, useModifierServiceDeleteModifier } from '../../../openapi/queries';
import { AssetProjectAsset } from '../../../openapi/requests';
import { Filter } from '../../../types/types';
import EditAssetProjectItem from './EditAssetProjectItem';
import { BASE_PATH } from '../../..';
import saveAs from 'file-saver';
import EditAssetProject from './EditAssetProject';
import ImportAssetProject from './ImportAssetProject';
import { UserSelector } from '../Inputs/UserSelector';
import { keepPreviousData } from '@tanstack/react-query';


const AssetProjectDetails: React.FC<{ projectId: number }> = ({ projectId }) => {

    const [query, setQuery] = useState("");
    const [debouncedQuery] = useDebounce(query, 500);
    const [selectedApprovalStatuses, setSelectedApprovalStatuses] = useState<number[]>([]);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sortField, setSortField] = useState<string>("id");
    const [sortOrder, setSortOrder] = useState<"desc" | "asc">("desc");
    const [selectedAssets, setSelectedAssets] = useState<AssetProjectAsset[]>([]);
    const [isEditorOpen, setIsEditorOpen] = useState(false);
    const [isItemEditorOpen, setIsItemEditorOpen] = useState(false);
    const [isImportOpen, setIsImportOpen] = useState(false);
    const [addNewWhenImporting, setAddNewWhenImporting] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState<AssetProjectAsset | undefined>(undefined);
    const [selectedUser, setSelectedUser] = useState<number | undefined>(undefined);
    const { mutateAsync } = useAssetProjectServicePutAssetProjectAssets();
    const [isWorking, setIsWorking] = useState(false);

    const { data: project } = useAssetProjectServiceGetAssetProject1({ key: projectId });
    const { mutateAsync: scan } = useAssetProjectServicePostAssetProjectScan();

    const filters = useMemo(() => {
        let filters: Filter[] = [];

        if (debouncedQuery) {
            filters.push({ type: 'search', values: [{value: debouncedQuery}], property: 'title,metadata.ean,metadata.product_range,metadata.product_type_primary,metadata.product_type_secondary,metadata.brand,metadata.banner,metadata.destination,metadata.case,metadata.room-type', name: 'title' });
        }

        if (selectedApprovalStatuses.length > 0) {
            filters.push({ type: 'select', values: selectedApprovalStatuses.map(e => ({value: e})), property: 'approvalStatus', name: 'approvalStatus' });
        }

        return filters;
    }, [debouncedQuery, selectedApprovalStatuses]);

    useEffect(() => {
        setPage(1);
    }, [debouncedQuery, selectedApprovalStatuses]);

    const { data, isFetching, refetch } = useAssetProjectServiceGetAssetProjectAssets({
        key: projectId,
        top: pageSize.toString(),
        skip: ((page - 1) * pageSize).toString(),
        filter: GenerateODataFilter(filters),
        orderby: `${sortField} ${sortOrder}`,
        count: 'true',
    }, undefined, { refetchOnWindowFocus: false, placeholderData: keepPreviousData });

    const { mutateAsync: deleteModel } = useModelServiceDeleteModel();
    const { mutateAsync: deleteMaterial } = useMaterialServiceDeleteMaterial();
    const { mutateAsync: deleteModelpack } = useModelPackServiceDeleteModelPack();
    const { mutateAsync: deleteModifier } = useModifierServiceDeleteModifier();

    const handleImportClose = useCallback(() => {
        setIsImportOpen(false);
        refetch();
    }, [refetch]);

    const handleEditorClose = useCallback(() => {
        setIsEditorOpen(false);
        refetch();
    }, [refetch]);

    const handleItemEditorClose = useCallback(() => {
        setIsItemEditorOpen(false);
        refetch();
    }, [refetch]);

    const handleScan = useCallback(() => {
        scan({ key: projectId }).then(() => {
            message.info("scan is queued");

            setTimeout(() => {
                refetch();
            }, 5_000);
        })
    }, [projectId, refetch, scan]);

    const handleImport = useCallback(() => {
        setIsImportOpen(true);
        setAddNewWhenImporting(false);
    }, []);

    const handleAddAssets = useCallback(() => {
        setIsImportOpen(true);
        setAddNewWhenImporting(true);
    }, []);


    const handleApprovalStatusChange = useCallback((value: any, option: { label: string; value: string; } | { label: string; value: string; }[]) => {
        if (Array.isArray(option)) {
            setSelectedApprovalStatuses(option.map(e => parseInt(e.value)));
        }
    }, []);

    const handleTableChange = useCallback((pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<AssetProjectAsset> | SorterResult<AssetProjectAsset>[]) => {
        setPage(pagination.current ?? 1);
        setPageSize(pagination.pageSize ?? 10);

        if (!Array.isArray(sorter)) {
            setSortField(sorter.field?.toString() ?? "id");
            setSortOrder(sorter.order === 'ascend' ? 'asc' : 'desc');
        }
    }, []);

    const exportAssets = useCallback(() => {
        const token = localStorage.getItem('accessToken');

        fetch(`${BASE_PATH}/assetproject/${projectId}/export`, {
            method: 'get',
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }).then(response => {
            return response.blob();
        }).then(blob => {
            saveAs(blob, "export.xlsx");
        });
    }, [projectId]);

    const handleMultiSave = useCallback(() => {
        if (selectedAssets.length > 0 && selectedUser) {
            setIsWorking(true);
            var promises = selectedAssets.map(asset => mutateAsync({ key: asset.id, id: asset.projectId?.toString() ?? '0', requestBody: { ...asset, producingArtistId: selectedUser } }));
            Promise.all(promises).then(() => {
                message.success("Assets updated");
                setIsWorking(false);
                refetch();
            }).catch((reason) => {
                message.error(reason?.toString());
                setIsWorking(false);
            })
        }
    }, [mutateAsync, refetch, selectedAssets, selectedUser]);

    const handleMultiDelete = useCallback(() => {
        if (selectedAssets.length > 0) {
            setIsWorking(true);

            let selection = [...selectedAssets];

            let onError = (reason: any) => {
                message.error(reason?.toString());
                setIsWorking(false);
            }

            let next = () => {
                if (selection.length > 0) {
                    var asset = selection[0];
                    selection.splice(0, 1);

                    if (asset.assetType === "Model") {
                        deleteModel({ key: asset.itemId }).then(next, onError);
                    } else if (asset.assetType === "Material") {
                        deleteMaterial({ key: asset.itemId }).then(next, onError);
                    } else if (asset.assetType === "Modifier") {
                        deleteModifier({ key: asset.itemId }).then(next, onError);
                    } else if (asset.assetType === "Modelpack") {
                        deleteModelpack({ key: asset.itemId }).then(next, onError);
                    } else {
                        next();
                    }
                } else {
                    message.success("Assets deleted");
                    setIsWorking(false);
                    refetch();
                }
            }

            next();
        }
    }, [deleteMaterial, deleteModel, deleteModelpack, deleteModifier, refetch, selectedAssets]);

    const handleDelete = useCallback((asset: AssetProjectAsset) => {
        if (asset.assetType === "Model") {
            deleteModel({ key: asset.itemId }).then(() => {
                message.success("asset deleted");
                refetch();
            });
        } else if (asset.assetType === "Material") {
            deleteMaterial({ key: asset.itemId }).then(() => {
                message.success("asset deleted");
                refetch();
            });
        } else if (asset.assetType === "Modifier") {
            deleteModifier({ key: asset.itemId }).then(() => {
                message.success("asset deleted");
                refetch();
            });
        } else if (asset.assetType === "Modelpack") {
            deleteModelpack({ key: asset.itemId }).then(() => {
                message.success("asset deleted");
                refetch();
            });
        }
    }, [deleteMaterial, deleteModel, deleteModelpack, deleteModifier, refetch]);

    const columns: ColumnsType<AssetProjectAsset> = [
        {
            title: 'Type',
            dataIndex: 'assetType',
            key: 'type'
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => (a.title || '').localeCompare(b.title || ''),
        },
        {
            title: 'Artist',
            dataIndex: 'producingArtist',
            key: 'producingArtist',
        },
        {
            title: 'Status',
            dataIndex: 'approvalStatus',
            key: 'approvalStatus',
            render: (_, value) => (AssetApprovalStatusOptions[value.approvalStatus as AssetApprovalStatus])
        },
        {
            title: 'Source',
            dataIndex: 'sourceType',
            key: 'sourceType',
            render: (_, value) => AssetSourceTypeLabels[(value.sourceType ?? 0) as AssetSourceType]
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => { setIsItemEditorOpen(true); setSelectedAsset(record) }}>Edit</Button>
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                        <Button danger type="link">Delete</Button>
                    </Popconfirm>
                    {record.workflowId && <Button type="link" onClick={() => window.open(`https://workflow.cadesignform.dk/Comment.aspx?id=${record.workflowId}`)}>Open in workflow</Button>}
                </Space>
            ),
        },
    ];

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        const assets = data.value.filter(e => newSelectedRowKeys.includes(e.id));
        setSelectedAssets(assets);

        const users = assets.map(e => e.producingArtistId);
        let uniqueUsers = [...new Set(users)];

        if (uniqueUsers.length === 1 && uniqueUsers[0] !== null) {
            setSelectedUser(uniqueUsers[0]);
        } else {
            setSelectedUser(undefined);
        }
    };

    const hasSelected = selectedAssets.length > 0;

    return (
        <>
            <h2>{project?.title}</h2>
            <Flex justify='space-between' style={{ marginBottom: 16 }}>

                <Space>
                    <Input placeholder='search' value={query} onChange={e => setQuery(e.target.value)} />
                    <Select
                        mode="multiple"
                        placeholder="Status"
                        onChange={handleApprovalStatusChange}
                        popupMatchSelectWidth={false}
                        size='middle'
                        style={{ minWidth: 100 }}
                        options={[
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.Quote],
                                value: AssetApprovalStatus.Quote.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.Pending],
                                value: AssetApprovalStatus.Pending.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.InProduction],
                                value: AssetApprovalStatus.InProduction.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.Commited],
                                value: AssetApprovalStatus.Commited.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.New],
                                value: AssetApprovalStatus.New.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.Rejected],
                                value: AssetApprovalStatus.Rejected.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.RejectedPendingModification],
                                value: AssetApprovalStatus.RejectedPendingModification.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.RejectedButModified],
                                value: AssetApprovalStatus.RejectedButModified.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.Approved],
                                value: AssetApprovalStatus.Approved.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.ApprovedPendingModification],
                                value: AssetApprovalStatus.ApprovedPendingModification.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.ApprovedButModified],
                                value: AssetApprovalStatus.ApprovedButModified.toString()
                            }
                        ]}
                    />
                </Space>

                <Space>
                    <Button type="primary" onClick={handleScan} icon={<SearchOutlined />}>
                        Scan sources
                    </Button>
                    <Button type="primary" onClick={exportAssets} icon={<ExportOutlined />}>
                        Export
                    </Button>
                    <Button type="primary" onClick={handleImport} icon={<ImportOutlined />}>
                        Import
                    </Button>
                    <Button type="primary" onClick={handleAddAssets} icon={<PlusOutlined />}>
                        Add
                    </Button>
                    <Button type="primary" onClick={() => setIsEditorOpen(true)} icon={<EditOutlined />}>
                        Edit
                    </Button>
                </Space>
            </Flex>

            <Table
                rowSelection={{
                    type: 'checkbox',
                    onChange: onSelectChange
                }}
                loading={isFetching}
                dataSource={data?.value ?? Array.from({ length: pageSize }).map(x => ({}))}
                columns={columns}
                rowKey={"id"}
                onChange={handleTableChange}
                pagination={{
                    total: (data !== undefined && data['@odata.count']) ? data['@odata.count'] : 0,
                    current: page,
                    pageSize: pageSize,
                    pageSizeOptions: [10, 20, 50, 100],
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    showQuickJumper: true,
                }} />

            <Flex align="center" gap="middle">
                <Form layout="inline" disabled={!hasSelected}>
                    <Form.Item label="Artist">
                        <UserSelector value={selectedUser} onChange={setSelectedUser} style={{ width: 250 }} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={handleMultiSave} loading={isWorking}>Save</Button>
                    </Form.Item>
                    <Form.Item>
                        <Popconfirm title="Sure to delete?" onConfirm={handleMultiDelete}>
                            <Button type="default" danger loading={isWorking}>Delete</Button>
                        </Popconfirm>
                    </Form.Item>
                    {hasSelected ? `Selected ${selectedAssets.length} items` : null}
                </Form>
            </Flex>

            <EditAssetProjectItem onClose={handleItemEditorClose} isOpen={isItemEditorOpen} asset={selectedAsset} />
            <EditAssetProject onClose={handleEditorClose} isOpen={isEditorOpen} project={project} />
            <ImportAssetProject addnew={addNewWhenImporting} onClose={handleImportClose} isOpen={isImportOpen} project={project} />
        </>
    );
};

export default AssetProjectDetails;
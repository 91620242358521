import React, { useContext, useState } from 'react';
import { ConfigContext } from '../../contexts/ConfigContext';
import { GenerateODataFilter } from '../../helpers/odataFunctions';
import { ProjectStatus, ProjectStatusLabels } from '../../models/enums';
import { useSearchProjectServiceGetSearchProject } from '../../openapi/queries';
import { AssetSearchResultDTO } from '../../openapi/requests';
import { Filter } from '../../types/types';
import Layout from '../layout/Layout';
import ProjectFilter from '../project/ProjectFilter';
import './ProjectSearch.scss';
import { AnimatePresence, motion } from 'framer-motion';
import { BASE_PATH } from '../..';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as BackIcon } from '../../assets/icons/arrow-left.svg';
import ProjectList from '../layout/ProjectList';

interface Props {
    selection: AssetSearchResultDTO[];
    mode: string;
    onAssetSelected: (asset: AssetSearchResultDTO) => void;
    onCancel: () => void;
}

const ProjectSearch: React.FC<Props> = ({ onAssetSelected, selection, mode, onCancel }) => {
    const { odataFilters } = useContext(ConfigContext);
    const [filters, setFilters] = useState<Filter[]>([{name: 'Status', property: 'status', type: 'select', values: [40, 50].map(e => ({value: e, label: ProjectStatusLabels[e as ProjectStatus]}))}]);

    const { data: projects, isLoading } = useSearchProjectServiceGetSearchProject({ mode: mode, assets: selection.map(e => e.name!), filter: GenerateODataFilter([...odataFilters, ...filters]) });

    return (<Layout className='project-search'>
        <header>
            <h1><BackIcon onClick={onCancel} /> Projects using:</h1>
            <div className='assets'>
                <AnimatePresence>
                    {selection.map(asset => <motion.div
                        key={asset.name}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className='asset'>
                        <img src={BASE_PATH + asset.thumbnail} alt={asset.title} />
                        <p>{asset.title}</p>
                        <div className='buttons'>
                            <button onClick={e => onAssetSelected(asset)}><DeleteIcon /></button>
                        </div>
                    </motion.div>)}
                </AnimatePresence>
            </div>
            <ProjectFilter filters={filters} onChange={e => setFilters(e)} isOpen={true} items={projects?.value ?? []} properties={[
                { name: 'Search', property: 'name', type: 'search' },
                { name: 'Status', property: 'status', type: 'select', itemsOverride: [0, 10, 20, 40, 50, 99].map(e => ({value: e, label: ProjectStatusLabels[e as ProjectStatus]})) },
                { name: 'Date', property: 'timestampCreate', type: 'daterange' }
            ]} />
        </header>
        <div className='search-page-container'>
            <ProjectList loading={isLoading} projects={projects?.value ?? []} />
        </div>
    </Layout>)
};

export default ProjectSearch;
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AssetService {

    /**
     * @returns string Success
     * @throws ApiError
     */
    public static getAssetTypesAll(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Asset/types/all',
        });
    }

    /**
     * @param type 
     * @returns string Success
     * @throws ApiError
     */
    public static getAssetMetadataNames(
type?: string,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Asset/metadata/names',
            query: {
                'type': type,
            },
        });
    }

    /**
     * @param name 
     * @param type 
     * @returns string Success
     * @throws ApiError
     */
    public static getAssetMetadataValues(
name?: string,
type?: string,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Asset/metadata/values',
            query: {
                'name': name,
                'type': type,
            },
        });
    }

    /**
     * @param name 
     * @param type 
     * @returns string Success
     * @throws ApiError
     */
    public static getAssetMetadataValues1(
name: string,
type?: string,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Asset/metadata/{name}/values',
            path: {
                'name': name,
            },
            query: {
                'type': type,
            },
        });
    }

}

import { SelectProps, Select, Spin } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { GenerateODataFilter } from "../../../helpers/odataFunctions";
import { MaterialService, MaterialViewDTO, ModelPackService, ModelPackViewDTO, ModelService, ModelViewDTO, ModifierService, ModifierViewDTO } from "../../../openapi/requests";

type AssetSelectorTypes = "Model" | "Material" | "Modelpack" | "Modifier";

export const AssetSelector: React.FC<SelectProps<number, DefaultOptionType> & {type: AssetSelectorTypes}> = (props) => {
    const [value, setValue] = useState("");
    const [debouncedValue] = useDebounce(value, 1000);
    
    const [valueItem, setValueItem] = useState<ModelViewDTO | MaterialViewDTO | ModelPackViewDTO | ModifierViewDTO | undefined>(undefined);
    const [values, setValues] = useState<(ModelViewDTO | MaterialViewDTO | ModelPackViewDTO | ModifierViewDTO)[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(props.value && !isNaN(props.value)){
            if(props.type === "Material"){
                MaterialService.getMaterial(undefined, '1', '0', `assetId eq ${props.value}`)
                    .then(val => {
                        if(val.value.length > 0){
                            setValueItem(val.value[0]);
                        }else{
                            setValueItem(undefined);
                        }
                    });
            } else if(props.type === "Model"){
                ModelService.getModel(undefined, '1', '0', `assetId eq ${props.value}`)
                    .then(val => {
                        if(val.value.length > 0){
                            setValueItem(val.value[0]);
                        }else{
                            setValueItem(undefined);
                        }
                    });
            } else if(props.type === "Modelpack"){
                ModelPackService.getModelPack(undefined, '1', '0', `assetId eq ${props.value}`)
                    .then(val => {
                        if(val.value.length > 0){
                            setValueItem(val.value[0]);
                        }else{
                            setValueItem(undefined);
                        }
                    });
            } else if(props.type === "Modifier"){
                ModifierService.getModifier(undefined, '1', '0', `assetId eq ${props.value}`)
                    .then(val => {
                        if(val.value.length > 0){
                            setValueItem(val.value[0]);
                        }else{
                            setValueItem(undefined);
                        }
                    });
            }
        }
    }, [props.type, props.value]);

    
    useEffect(() => {
        if(debouncedValue.length > 2){
            setIsLoading(true);
            if(props.type === "Material"){
                MaterialService.getMaterial(undefined, '50', '0', GenerateODataFilter([{ type: 'search', values: [{value: debouncedValue}], property: 'title,metadata.ean,metadata.product_type_primary,metadata.product_type_secondary', name: 'title' }]))
                    .then(val => {
                        setValues(val.value);
                        setIsLoading(false);
                    });
            } else if(props.type === "Model"){
                ModelService.getModel(undefined, '50', '0', GenerateODataFilter([{ type: 'search', values: [{value: debouncedValue}], property: 'title,metadata.ean,metadata.product_type_primary,metadata.product_type_secondary', name: 'title' }]))
                    .then(val => {
                        setValues(val.value);
                        setIsLoading(false);
                    });
            } else if(props.type === "Modelpack"){
                ModelPackService.getModelPack(undefined, '50', '0', GenerateODataFilter([{ type: 'search', values: [{value: debouncedValue}], property: 'title,metadata.ean,metadata.product_type_primary,metadata.product_type_secondary', name: 'title' }]))
                    .then(val => {
                        setValues(val.value);
                        setIsLoading(false);
                    });
            } else if(props.type === "Modifier"){
                ModifierService.getModifier(undefined, '50', '0', GenerateODataFilter([{ type: 'search', values: [{value: debouncedValue}], property: 'title,metadata.ean,metadata.product_type_primary,metadata.product_type_secondary', name: 'title' }]))
                    .then(val => {
                        setValues(val.value);
                        setIsLoading(false);
                    });
            }
        }
    }, [debouncedValue, props.type]);

    return (
        <Select
            showSearch
            filterOption={false}
            onSearch={setValue}
            notFoundContent={isLoading ? <Spin size="small" /> : "No matches"}
            {...props}
            options={[
                { label: valueItem?.title, value: valueItem?.assetId.toString() }, 
                ...(values.filter(e => e.id !== valueItem?.id).map(e => ({ label: e.title, value: e.assetId.toString() })) ?? [])
            ].filter(e => e.value !== undefined)}
        />
    );
}
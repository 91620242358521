import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Select, { components, OptionProps, StylesConfig } from "react-select";
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { useDebouncedCallback } from 'use-debounce';
import "./elements/Filter.scss";
import { Filter, InputProps, Property, Props } from '../../types/types';
import { MetadataDTO } from '../../openapi/requests';

const AssetFilter: React.FC<Props> = ({ properties, onChange, filters, items, isOpen }) => {
   
    const hasSearch = useMemo(() => {
        return filters.filter(x => x.type === "search").length > 0;
    },[filters]);

    const onFilterAdded = useCallback((filter: Filter) => {
        onChange([...filters.filter(e => e.property !== filter.property), filter]);
    }, [filters, onChange]);

    const clearAll = useCallback(() => {
        onChange([]);
    }, [onChange]);

    const onFilterRemove = useCallback((filter: Filter, value: string | number | boolean) => {
        onChange([...filters.filter(e => e.property !== filter.property)]);        
    }, [filters, onChange]);

    const renderInput = useCallback((prop: Property) => {
        switch (prop.type) {
            case "select": {
                return <AssetFilterInput
                    key={prop.property}
                    name={prop.name}
                    property={prop.property}
                    onChange={onFilterAdded}                          
                    itemsOverride={prop.itemsOverride}
                    items={items}                    
                    filter={filters.find(e => e.property === prop.property) ?? { name: prop.name, property: prop.property, values: [], type: prop.type }} />
            }
            case "search": {
                return <AssetSearchInput
                    key={prop.property}
                    name={prop.name}                  
                    property={prop.property}
                    onChange={onFilterAdded}   
                    hasSearch={hasSearch}                        
                    itemsOverride={prop.itemsOverride}
                    items={items}                  
                    filter={filters.find(e => e.property === prop.property) ?? { name: prop.name, property: prop.property, values: [], type: prop.type }} />
            }
        }
    },[filters, items, onFilterAdded, hasSearch]);

    return (
        <div className='filter'>
            <div className='filter-properties'>
                {properties.map((prop,i)=>
                    renderInput(prop)
                )}
            </div>

            <div className='filter-values'>
                {(filters.some(e => e.values.length > 0)) && <div className='clear' onClick={clearAll}>Clear all filters</div>}
                {filters.map(filter => filter.values.map(value => <div key={value.value.toString()} className='filter-value'>{filter.name}: {value.label ?? value.value}<CloseIcon onClick={() => onFilterRemove(filter, value.value)} /> </div>))}
            </div>
        </div>
    )
};

interface Option {
    value: string;
    label: string;
}

const colourStyles: StylesConfig<Option, true> = {    
    control: (styles) => ({
        ...styles,
        borderRadius: '0',
        fontSize: '12px',
        minHeight: '32px'      
    }),
    indicatorSeparator: (styles) => ({
        ...styles,
        display: 'none',
    }),    
    dropdownIndicator: (styles) => ({
        ...styles,
        padding: '0px 8px 0px 0px'
    }),    
    clearIndicator: (styles) => ({
        ...styles,
        padding: '0px 0px 0px 0px'
    }),     
    noOptionsMessage: (styles) => ({
        ...styles,
        fontSize: '12px',
    }),    
    option: (styles, { data, isDisabled, isFocused, isSelected}) => ({   
        ...styles,
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
        color: (isSelected) ? '#FFF': (isFocused) ? '#FFF' : '#000',
        backgroundColor: (isSelected) ? '#000': (isFocused) ? 'rgba(0,0,0,.26)' : '#FFF',        
        ':active': {
            ...styles[':active'],
            color: (isSelected) ? '#FFF': (isFocused) ? '#FFF' : '#000',
            backgroundColor: (isSelected) ? '#000': (isFocused) ? 'rgba(0,0,0,.26)' : '#FFF',
        }                 
    })        
};

const OptionComponent = (props: OptionProps<Option>) => {
    return (
        <components.Option key={props.data.value} {...props}> <input type="checkbox" checked={props.isSelected} onChange={() => {}} /> {props.children}</components.Option>
    );
};

const AssetSearchInput: React.FC<InputProps> = ({ name, hasSearch, property, onChange, items, filter }) => {

    const elementRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (!hasSearch && elementRef.current) {
            elementRef.current.value = "";
        }
    }, [hasSearch])

    const handleChange = useCallback((e: any) => {      
        var values = (e.target.value !== "") ? [e.target.value] : [];

        onChange({
            property: property,
            name: name,
            values: values,
            type: filter.type
        });
    }, [onChange, property, name, filter]);    

    const onDebouncedChange = useDebouncedCallback(handleChange, 500);

    return (
        <div className='filter-input'>
            <div className='text-input search'>              
                <input type="search" ref={elementRef} placeholder={`${name}...`} onChange={(e) => onDebouncedChange(e)} />
            </div>
        </div>
    )
}

const AssetFilterInput: React.FC<InputProps> = ({ name, property, onChange, itemsOverride, items, filter }) => {

    const options = useMemo(() => {
        if(itemsOverride) {
            return itemsOverride.map(e => ({value: e.value, label: e.label ?? e.value}));
        }

        let values = items.map(e => e.metadata.find((m: MetadataDTO) => m.name.toLowerCase() === property.toLowerCase())?.value ?? "")
            .filter(e => e !== "");
        values = Array.from(new Set(values));
        return values.map(e => ({ value: e, label: e }));

    }, [itemsOverride, items, property]);

    const handleChange = useCallback((options: readonly Option[]) => {
        onChange({
            property: property,            
            name: name,
            values: options.map(e => e),
            type: filter.type
        });
    }, [onChange, property, name, filter]);

    return (
        <div className='filter-input'>            
            <Select
                isMulti
                placeholder={name}
                components={{ Option: OptionComponent }}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                theme={(theme) =>
                    ({
                        ...theme,                                      
                        colors: {
                            ...theme.colors,
                            primary: 'black',
                            primary25: 'black',
                        }
                    })                    
                }
                value={filter.values.map(e => ({ value: e.value.toString(), label: e.label ?? e.value.toString() }))}
                onChange={handleChange}
                options={options}
                styles={colourStyles}
                controlShouldRenderValue={false}
            />
        </div>
    )
};

export default AssetFilter;
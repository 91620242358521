import React, { createContext, FC, useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "./AuthContext";
import { useClientServiceGetClient, useUserServiceGetUserMe } from "../openapi/queries";
import { ClientDTO } from "../openapi/requests";
import { Filter } from "../types/types";

interface MetadataKeys {
    batchOrderPrefix: string;
    itemlistDetail: string;
}

interface FilterProperty {
    fields: string[];
    label: string;
    type: 'search' | 'select';
}

interface AssetFilter {
    fields: FilterProperty[];
}

interface Filters {
    templates: AssetFilter;
}

export interface FeatureFlags {
    ProjectFolders: boolean;
    UseLatestRenderingInMoodboard: boolean;
}

export interface ConfigContextState {
    availableClients: ClientDTO[];
    currentClient: ClientDTO;
    userClient: ClientDTO;
    setCurrentClient: (client: ClientDTO) => void;
    metadataKeys: MetadataKeys;
    filters: Filters;
    odataAssetFilters: Filter[];
    odataFilters: Filter[];
    featureFlags: FeatureFlags;
};

const contextDefaultValues: ConfigContextState = {
    availableClients: [],
    currentClient: {id:0, name: 'default'},
    userClient: {id:0, name: 'default'},
    setCurrentClient: () => {},
    metadataKeys: { 
        batchOrderPrefix: 'ean',
        itemlistDetail: 'ean'
    },
    filters: {
        templates: {
            fields: [
                {fields: ['title'], label: 'Search', type: 'search'},
            ]
        }
    },
    odataAssetFilters: [],
    odataFilters: [],
    featureFlags: {
        ProjectFolders: true,
        UseLatestRenderingInMoodboard: false,
    }
};

export const ConfigContext = createContext<ConfigContextState>(
    contextDefaultValues
);

const clientMetadataKeys: {[key: string]: MetadataKeys} = {
    'default': contextDefaultValues.metadataKeys,
    'kingfisher': { batchOrderPrefix: 'ean', itemlistDetail: 'ean' }
}

const clientFilters: {[key: string]: Filters} = {
    'default': contextDefaultValues.filters,
    'kingfisher': {
        templates: {
            fields: [
                {fields: ['title'], label: 'Search', type: 'search'},
                {fields: ['metadata.brand'], label: 'Brand', type: 'select'},
                {fields: ['metadata.range'], label: 'Range', type: 'select'},
                {fields: ['metadata.category'], label: 'Category', type: 'select'},
            ]
        }
    }
}

export const ConfigProvider: FC<{ children: React.ReactNode }> = ({ children }) => {

    const { isLoggedIn, hasRole } = useContext(AuthContext);
    const { data:clients } = useClientServiceGetClient({}, undefined, {enabled: isLoggedIn});
    const { data: user} = useUserServiceGetUserMe(undefined, {enabled: isLoggedIn}); 

    const [currentClient, setCurrentClient] = useState<ClientDTO>({id:0, name: 'default'});
    const [userClient, setUserClient] = useState<ClientDTO>({id:0, name: 'default'});

    useEffect(() => {
        if(clients){
            let cadesign = clients.value.find(e => e.name === "Cadesign");

            if(cadesign){
                setCurrentClient(cadesign);
            }else{
                setCurrentClient(clients.value[0]);
            }

            if(user){
                setUserClient(clients.value.find(e => e.id === user.clientId) ?? {id:0, name: 'default'});
            }
        }
    }, [clients, user]);

    const metadataKeys = clientMetadataKeys[currentClient.name.toLowerCase()] ?? clientMetadataKeys['default'];
    const filters = clientFilters[currentClient.name.toLowerCase()] ?? clientFilters['default'];

    const odataAssetFilters = useMemo(() => {
        let filters: Filter[] = [];
        filters.push({name: 'client', property: 'clientid', values: [{value: currentClient.id}], type: 'select'});
        
        if(!hasRole("Cadesign")){
            filters.push({name: 'client', property: 'isEnabled', values: [{value: true}], type: 'select'});
        }

        return filters;
    }, [currentClient, hasRole]);

    const odataFilters = useMemo(() => {
        let filters: Filter[] = [
            {name: 'client', property: 'clientid', values: [{value: currentClient.id}], type: 'select'}
        ];

        return filters;
    }, [currentClient]);

    const featureFlags: FeatureFlags = {
        // eslint-disable-next-line no-restricted-globals
        ProjectFolders: true, //location.hostname.includes("localhost") || location.hostname.includes("internal")
        UseLatestRenderingInMoodboard: true
    }

    return (
        <ConfigContext.Provider
            value={{
                currentClient,
                setCurrentClient,
                userClient,
                availableClients: clients?.value ?? [],
                metadataKeys,
                filters,
                odataAssetFilters,
                odataFilters,
                featureFlags,
            }}
        >
            {children}
        </ConfigContext.Provider>
    );
};